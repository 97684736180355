'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { BlockDesign, HubButtonData, ImageMeta } from '../types';
import { DynamicRenderBlockNote } from '../ui';
import CloudinaryImage from '../ui/cloudinary-image';
import { HubButton } from '../ui/hub-button';
import { PlainText } from '../ui/plain-text';
import { emitBlockClickEvent } from '../utils';

export interface HeroBlockProps {
  blockId: string;
  content: {
    backgroundImage?: ImageMeta | null;
    backgroundImageAltText?: string;
    backgroundOverlayDarkness?: number;
    backgroundVideoUrl?: string;
    buttons?: HubButtonData[];
    contentPlacement?: string;
    description?: string;
    design?: BlockDesign;
    heading?: string;
    minHeightFull?: boolean;
    variant?:
      | 'angled_image'
      | 'straight_image'
      | 'angled_image_left'
      | 'straight_image_left'
      | 'full_image_background'
      | 'full_video_background';
  };
  dynamicOffset?: number;
  index?: number;
  isPreview?: boolean;
  removeBottomSpacing?: boolean;
  removeTopSpacing?: boolean;
  renderDescription: (textColor: string) => React.ReactNode;
}

const PLACEHOLDER_HEADING = 'Data to enrich your online business';
const PLACEHOLDER_DESCRIPTION =
  'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.';

// Utility function to map contentPlacement to CSS classes
const getContentPlacementClasses = (placement = 'center_center') => {
  switch (placement) {
    case 'top_left':
      return 'justify-start items-start';
    case 'top_center':
      return 'justify-start items-center text-center';
    case 'top_right':
      return 'justify-start items-end text-right';
    case 'center_left':
      return 'justify-center items-start';
    case 'center_center':
      return 'justify-center items-center text-center';
    case 'center_right':
      return 'justify-center items-end text-right';
    case 'bottom_left':
      return 'justify-end items-start';
    case 'bottom_center':
      return 'justify-end items-center text-center';
    case 'bottom_right':
      return 'justify-end items-end text-right';
    default:
      return 'justify-center items-center'; // Default to center if no match
  }
};

function renderButtons(
  buttons: HubButtonData[],
  contentPlacement = 'center_center'
) {
  if (!buttons.length || buttons.every((button) => button.label === ''))
    return null;

  // Ensure contentPlacement is a string
  const placement =
    typeof contentPlacement === 'string' ? contentPlacement : 'center_center';

  const alignmentClass = placement.includes('right')
    ? 'justify-end'
    : placement.includes('left')
    ? 'justify-start'
    : 'justify-center';

  return (
    <div
      className={clsx(
        'z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row',
        alignmentClass
      )}
    >
      {buttons.map((button, index) => {
        if (!button.label) return null;
        return (
          <HubButton
            key={index}
            className="w-full justify-center md:w-auto"
            hasArrow={button.hasArrow}
            isPrimary={button.isPrimary}
            label={button.label}
            openInNewTab={button.openInNewTab}
            size="lg"
            url={button.url}
          />
        );
      })}
    </div>
  );
}

export const AngledHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        'relative',
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      id="hero_block"
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="mx-auto grid w-full max-w-screen-xl overflow-hidden lg:grid-cols-12 lg:gap-x-16">
        <div className="relative z-10 flex items-center lg:col-span-8 lg:w-full lg:max-w-2xl xl:col-span-7">
          <svg
            aria-hidden="true"
            className="absolute inset-y-0 right-0 hidden h-[101%] w-80 translate-x-1/2 fill-hubs-background lg:block"
            preserveAspectRatio="none"
            style={{ fill: content.design?.backgroundColor }}
            viewBox="0 0 100 100"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
          <div className="z-10 flex max-w-2xl flex-col items-start gap-8 px-4 py-24 sm:px-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:pr-0 2xl:min-h-[720px]">
            <h1 className="font-heading text-4xl font-semibold text-hubs-primary md:text-5xl">
              {content.heading ?? PLACEHOLDER_HEADING}
            </h1>
            {renderDescription(
              'text-hubs-secondary prose-headings:text-hubs-secondary prose-p:text-hubs-secondary prose-a:text-hubs-secondary prose-strong:text-hubs-secondary prose-table:text-hubs-secondary marker:text-hubs-secondary'
            )}
            {renderButtons(
              content.buttons ? content.buttons : [],
              content.contentPlacement
            )}
          </div>
        </div>
      </div>
      <div className="overflow-hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:py-0">
        {content.backgroundImage && (
          <CloudinaryImage
            alt={
              content.backgroundImageAltText ||
              content.backgroundImage.originalFilename ||
              'Background image'
            }
            className="aspect-video h-full w-full object-cover lg:aspect-auto lg:object-left"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const StraightHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        'relative',
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div
        className={clsx(
          BLOCK_WIDTH_CLASS,
          'relative z-[1] grid lg:grid-cols-12 lg:gap-x-16'
        )}
      >
        <div
          className={clsx(
            'flex max-w-2xl flex-col gap-8 py-24 sm:pr-6 lg:col-span-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:px-0 xl:col-span-6 2xl:min-h-[720px]',
            !content.design?.backgroundColor && 'bg-hubs-background'
          )}
        >
          <h1 className="font-heading text-4xl font-semibold text-hubs-primary sm:mt-10 md:text-5xl lg:mt-0">
            {content.heading ?? PLACEHOLDER_HEADING}
          </h1>
          {renderDescription(
            'text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary'
          )}
          {renderButtons(
            content.buttons ? content.buttons : [],
            content.contentPlacement
          )}
        </div>
      </div>
      <div
        className="relative z-0 lg:absolute lg:inset-0 lg:left-1/2 lg:-ml-8 lg:bg-transparent lg:py-0 xl:ml-0"
        style={{
          backgroundColor: content.design?.backgroundColor || undefined,
        }}
      >
        {content.backgroundImage && content.backgroundImage.cloudinaryUrl && (
          <CloudinaryImage
            alt=""
            className="aspect-video h-full w-full object-cover lg:absolute lg:right-0 lg:aspect-auto lg:h-full lg:w-[90%] lg:object-cover"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0 ? true : false}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const AngledHeroBlockLeft: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        'relative',
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      id="hero_block"
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="mx-auto grid w-full max-w-screen-xl overflow-hidden lg:grid-cols-12 lg:gap-x-16">
        <div className="relative z-10 flex items-center lg:col-span-7 lg:col-start-6 lg:w-full lg:max-w-2xl xl:col-start-6">
          <svg
            aria-hidden="true"
            className="absolute inset-y-0 left-0 hidden h-[101%] w-80 -translate-x-1/2 fill-hubs-background lg:block"
            preserveAspectRatio="none"
            style={{ fill: content.design?.backgroundColor }}
            viewBox="0 0 100 100"
          >
            <polygon points="10,0 100,0 100,100 50,100" />
          </svg>
          <div className="z-10 flex max-w-2xl flex-col items-start gap-8 px-4 py-24 sm:px-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:pr-0 2xl:min-h-[720px]">
            <h1 className="font-heading text-4xl font-semibold text-hubs-primary md:text-5xl">
              {content.heading ?? PLACEHOLDER_HEADING}
            </h1>
            {renderDescription(
              'text-hubs-secondary prose-headings:text-hubs-secondary prose-p:text-hubs-secondary prose-a:text-hubs-secondary prose-strong:text-hubs-secondary prose-table:text-hubs-secondary marker:text-hubs-secondary'
            )}
            {renderButtons(
              content.buttons ? content.buttons : [],
              content.contentPlacement
            )}
          </div>
        </div>
      </div>
      <div className="overflow-hidden lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 lg:py-0">
        {content.backgroundImage && (
          <CloudinaryImage
            alt={
              content.backgroundImageAltText ||
              content.backgroundImage.originalFilename ||
              'Background image'
            }
            className="aspect-video h-full w-full object-cover lg:aspect-auto lg:object-right"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const StraightHeroBlockLeft: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        'relative',
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div
        className={clsx(
          BLOCK_WIDTH_CLASS,
          'relative z-[1] grid lg:grid-cols-12 lg:gap-x-16'
        )}
      >
        <div
          className={clsx(
            'flex max-w-2xl flex-col gap-8 py-24 sm:pl-6 lg:col-span-6 lg:col-start-7 lg:mx-0 lg:justify-center lg:px-0 xl:col-span-6 xl:col-start-7 2xl:min-h-[720px]',
            !content.design?.backgroundColor && 'bg-hubs-background'
          )}
        >
          <h1 className="font-heading text-4xl font-semibold text-hubs-primary sm:mt-10 md:text-5xl lg:mt-0">
            {content.heading ?? PLACEHOLDER_HEADING}
          </h1>
          {renderDescription(
            'text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary'
          )}
          {renderButtons(
            content.buttons ? content.buttons : [],
            content.contentPlacement
          )}
        </div>
      </div>
      <div
        className="relative z-0 lg:absolute lg:inset-0 lg:right-1/2 lg:-mr-8 lg:bg-transparent lg:py-0 xl:mr-0"
        style={{
          backgroundColor: content.design?.backgroundColor || undefined,
        }}
      >
        {content.backgroundImage && content.backgroundImage.cloudinaryUrl && (
          <CloudinaryImage
            alt=""
            className="aspect-video h-full w-full object-cover lg:absolute lg:left-0 lg:aspect-auto lg:h-full lg:w-[90%] lg:object-cover"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0 ? true : false}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const FullImageBackgroundHeroBlock: React.ComponentType<
  HeroBlockProps
> = ({
  blockId,
  content,
  dynamicOffset,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        `relative flex flex-col`,
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
        minHeight: content.minHeightFull
          ? `calc(100vh - ${dynamicOffset}px)`
          : undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.backgroundImage && content.backgroundImage.cloudinaryUrl && (
        <CloudinaryImage
          alt=""
          className="absolute inset-0 h-full w-full object-cover"
          fill={true}
          priority={index === 0}
          sizes="100vw"
          src={content.backgroundImage.cloudinaryUrl || ''}
        />
      )}
      {content.backgroundOverlayDarkness ? (
        <div
          className="absolute inset-0 z-[1] bg-black"
          style={{ opacity: content.backgroundOverlayDarkness / 9 }}
        ></div>
      ) : null}
      <div
        className={clsx(
          BLOCK_WIDTH_CLASS,
          'relative z-10 my-16 flex h-full grow flex-col',
          getContentPlacementClasses(content.contentPlacement)
        )}
      >
        <div
          className={clsx(
            'relative z-10 flex max-w-2xl flex-col gap-8 py-24 lg:gap-x-16',
            !content.design?.backgroundColor && 'bg-transparent'
          )}
        >
          <h1 className="font-heading text-4xl font-semibold text-white sm:mt-10 md:text-5xl md:leading-[1.2] lg:mt-0">
            {content.heading ?? PLACEHOLDER_HEADING}
          </h1>
          {renderDescription(
            'text-white prose-headings:text-white prose-p:text-white prose-a:text-white prose-strong:text-white prose-table:text-white marker:text-white'
          )}
          {renderButtons(
            content.buttons ? content.buttons : [],
            content.contentPlacement
          )}
        </div>
      </div>
    </div>
  );
};

export const FullVideoBackgroundHeroBlock: React.ComponentType<
  HeroBlockProps
> = ({
  blockId,
  content,
  dynamicOffset,
  removeBottomSpacing,
  removeTopSpacing,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        'relative flex flex-col',
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      style={{
        backgroundColor: content.design?.backgroundColor || undefined,
        minHeight: content.minHeightFull
          ? `calc(100vh - ${dynamicOffset}px)`
          : undefined,
      }}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.backgroundVideoUrl && (
        <div className="absolute inset-0 z-0">
          <video
            autoPlay
            loop
            muted
            className="pointer-events-none h-full w-full object-cover"
            preload="auto"
            src={content.backgroundVideoUrl}
          />
          {content.backgroundOverlayDarkness ? (
            <div
              className="absolute inset-0 bg-black"
              style={{ opacity: content.backgroundOverlayDarkness / 9 }}
            ></div>
          ) : null}
        </div>
      )}
      <div
        className={clsx(
          BLOCK_WIDTH_CLASS,
          'relative z-10 my-16 flex h-full grow flex-col',
          getContentPlacementClasses(content.contentPlacement)
        )}
      >
        <div
          className={clsx(
            'relative z-10 flex max-w-2xl flex-col gap-8 py-24 lg:gap-x-16',
            !content.design?.backgroundColor && 'bg-transparent'
          )}
        >
          <h1 className="font-heading text-4xl font-semibold text-white sm:mt-10 md:text-5xl md:leading-[1.2] lg:mt-0">
            {content.heading ?? 'PLACEHOLDER_HEADING'}
          </h1>
          {renderDescription(
            'text-white prose-headings:text-white prose-p:text-white prose-a:text-white prose-strong:text-white prose-table:text-white marker:text-white'
          )}
          {renderButtons(
            content.buttons ? content.buttons : [],
            content.contentPlacement
          )}
        </div>
      </div>
    </div>
  );
};

export const HeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  isPreview = false,
  removeBottomSpacing,
  removeTopSpacing,
}) => {
  const [dynamicOffset, setDynamicOffset] = useState(() => {
    if (index && index > 0) return 0;
    return isPreview ? 243 : 120;
  });

  /*
    Dynamic offset is used to offset the hero block so that it doesn't include the headers height in the calculation of the min height.
    Eg. if we set minHeightFull to true, the hero block will take up the full height of the window. But we need to minus the header height from the calculation.
    On preview, the dynamic offset is 243px (the height of the header + hub builder header).
    On live site, the dynamic offset is 120px (the height of the header).
    On mobile, the dynamic offset is 72px (the header is smaller).
  */
  useEffect(() => {
    const updateDynamicOffset = () => {
      // If the hero block is not the first block, we don't need to worry about the header height.
      if (index && index > 0) {
        setDynamicOffset(0);
      } else if (!isPreview && window.innerWidth < 1024) {
        setDynamicOffset(72);
      } else {
        setDynamicOffset(isPreview ? 243 : 120);
      }
    };

    updateDynamicOffset();
    window.addEventListener('resize', updateDynamicOffset);

    return () => {
      window.removeEventListener('resize', updateDynamicOffset);
    };
  }, [isPreview, index]);

  function renderDescription(textColor: string) {
    if (content.description) {
      if (typeof content.description === 'string') {
        return (
          <PlainText
            className={clsx('font-body text-lg leading-normal', textColor)}
            content={content.description ?? PLACEHOLDER_DESCRIPTION}
          />
        );
      }

      return (
        <DynamicRenderBlockNote
          className={clsx(
            'blocknote prose max-w-none list-inside list-disc font-body text-lg leading-normal',
            textColor
          )}
          content={content.description}
        />
      );
    }
    return null;
  }

  if (content.variant === 'angled_image') {
    return (
      <AngledHeroBlock
        blockId={blockId}
        content={content}
        dynamicOffset={dynamicOffset}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderDescription={renderDescription}
      />
    );
  }

  if (content.variant === 'angled_image_left') {
    return (
      <AngledHeroBlockLeft
        blockId={blockId}
        content={content}
        dynamicOffset={dynamicOffset}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderDescription={renderDescription}
      />
    );
  }

  if (content.variant === 'straight_image_left') {
    return (
      <StraightHeroBlockLeft
        blockId={blockId}
        content={content}
        dynamicOffset={dynamicOffset}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderDescription={renderDescription}
      />
    );
  }

  if (content.variant === 'full_image_background') {
    return (
      <FullImageBackgroundHeroBlock
        blockId={blockId}
        content={content}
        dynamicOffset={dynamicOffset}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderDescription={renderDescription}
      />
    );
  }

  if (content.variant === 'full_video_background') {
    return (
      <FullVideoBackgroundHeroBlock
        blockId={blockId}
        content={content}
        dynamicOffset={dynamicOffset}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderDescription={renderDescription}
      />
    );
  }

  return (
    <StraightHeroBlock
      blockId={blockId}
      content={content}
      dynamicOffset={dynamicOffset}
      index={index}
      removeBottomSpacing={removeBottomSpacing}
      removeTopSpacing={removeTopSpacing}
      renderDescription={renderDescription}
    />
  );
};
