import React, { useCallback, useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import 'regenerator-runtime/runtime';
import {
  ArrowRightIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react-v2/24/solid';
import { useRouter } from 'next/router';
import { AttemptedAction } from '../../../context/sign-up-on-page-context';
import {
  BlockHeading,
  InvestorQasBlockCardItem,
  InvestorQasBlockTabs,
  Skeleton,
  Typography,
  InvestorMediaCommentsQuery,
  tabItem,
  useInvestorMediaCommentsQuery,
  SignUpOnPageModal,
  CurrentInvestorUserQuery,
  Carousel,
} from '../../../index';
import JoinHubBlock from './join-hub-block';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  companyId: string;
  companyName: string;
  content: {
    category?: 'default' | 'starred';
    heading?: string;
  };
  currentInvestorUser?: CurrentInvestorUserQuery['currentInvestorUser'];
  hub: string;
  isUK: boolean;
  listingKey: string;
  login?: (
    companyProfileId: string,
    email: string,
    password: string,
    rememberMe?: boolean,
    shareholderHashId?: string
  ) => Promise<void>;
  logoUrl: string;
  marketKey: string;
  marketListingKey: string;
  translate: (key: string) => string;
}

export type InvestorMediaCommentInBlock = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<InvestorMediaCommentsQuery['investorMediaComments']>['edges']
    >[0]
  >['node']
>;

const ROWS_PER_PAGE = 3;

export const InvestorQasBlock: React.ComponentType<Props> = ({
  client,
  companyId,
  companyName,
  content,
  currentInvestorUser,
  hub,
  isUK,
  listingKey,
  login,
  logoUrl,
  marketKey,
  marketListingKey,
}) => {
  const router = useRouter();
  const { asPath, isReady, push } = router;

  // Scroll to investor-qas-block
  useEffect(() => {
    const match = asPath.includes('#investor-qas-block');

    if (match && !!document.getElementById('investor-qas-block')) {
      setTimeout(() => {
        document.getElementById('investor-qas-block')?.scrollIntoView();
      }, 1500);
    }
  }, [asPath, isReady]);

  // Current Investor user account related data
  const isSignedIn = !!currentInvestorUser;
  const isVerified = !!currentInvestorUser?.confirmedAt;
  const isSetUsername = !!currentInvestorUser?.username;
  const isFullySetup = isSignedIn && isVerified && isSetUsername;

  const [openSignUpOnPageModal, setOpenSignUpOnPageModal] = useState(false);
  const [selectedMediaCommentId, setSelectedMediaCommentId] = useState<
    string | undefined
  >(undefined);
  const [attemptedAction, setAttemptedAction] = useState<
    AttemptedAction | undefined
  >(undefined);

  const [selectedTab, setSelectedTab] = useState(tabItem.Popular);

  const { data, loading } = useInvestorMediaCommentsQuery({
    client,
    variables: {
      first: 10,
      hub,
      options: {
        filters: [{ key: 'category', value: content?.category ?? 'default' }],
        orders:
          content?.category === 'starred'
            ? []
            : [{ key: 'type', value: selectedTab }],
      },
    },
  });

  const renderList = useCallback(() => {
    if (loading) {
      return (
        <div className="space-y-4">
          {Array.from(new Array(ROWS_PER_PAGE).keys()).map((item) => (
            <Skeleton key={item} loading height={60} variant="rect" />
          ))}
        </div>
      );
    }

    if (data?.investorMediaComments?.edges?.length) {
      const mediaCommentsCards = [
        ...data.investorMediaComments.edges.map((edge) => {
          if (edge?.node) {
            return (
              <InvestorQasBlockCardItem
                key={edge.node.id}
                companyName={companyName}
                investorMediaComment={edge.node}
                isFullySetup={isFullySetup}
                isSignedIn={isSignedIn}
                listingKey={listingKey}
                logoUrl={logoUrl}
                marketKey={marketKey}
                setAttemptedAction={setAttemptedAction}
                setOpenSignUpOnPageModal={setOpenSignUpOnPageModal}
                setSelectedMediaCommentId={setSelectedMediaCommentId}
              />
            );
          }
          return null;
        }),
        currentInvestorUser ? null : (
          <div className="h-full min-h-[485px] w-full space-y-6 rounded-lg border border-secondary-grey-light p-4">
            <JoinHubBlock
              companyName={companyName}
              logoUrl={logoUrl}
              setOpenSignUpOnPageModal={setOpenSignUpOnPageModal}
            />
          </div>
        ),
      ].filter((item) => item !== null);

      return (
        <Carousel
          numOfSlidesPerRow={4}
          options={{ align: 'start', loop: true }}
          showNavigationInCarouselMiddle={mediaCommentsCards.length > 3}
          showPrevNextButtonNavigation={false}
          slides={mediaCommentsCards}
        />
      );
    }

    return (
      <div className="flex flex-col items-center space-y-6 rounded-lg bg-gray-50 py-24">
        <ChatBubbleLeftRightIcon className="h-8 w-8" />
        <Typography className="text-center" variant="heading-4">
          Be the first to post a question
        </Typography>
        <div className="flex items-center gap-1">
          <Typography>
            You can post your question on one of our{' '}
            <span className="underline" onClick={() => push('/announcements')}>
              announcements
            </span>{' '}
            or{' '}
            <span
              className="underline"
              onClick={() => push('/activity-updates')}
            >
              updates
            </span>
          </Typography>
          <ArrowRightIcon className="h-5 w-5" />
        </div>
      </div>
    );
  }, [
    loading,
    data?.investorMediaComments,
    companyName,
    currentInvestorUser,
    logoUrl,
    isFullySetup,
    isSignedIn,
    listingKey,
    marketKey,
    push,
  ]);

  return (
    <div id="investor-qas-block">
      <div className="w-full max-w-screen-xl space-y-6 px-4 sm:px-6 lg:mx-auto">
        {content?.heading && content.heading.length > 0 && (
          <BlockHeading className="mb-4">{content.heading}</BlockHeading>
        )}

        {content?.category !== 'starred' && (
          <InvestorQasBlockTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}

        {renderList()}
      </div>

      <SignUpOnPageModal
        attemptedAction={attemptedAction}
        companyId={companyId}
        companyName={companyName}
        currentInvestorUser={currentInvestorUser}
        isUK={isUK}
        listingKey={listingKey}
        login={login}
        logoUrl={logoUrl}
        marketKey={marketKey}
        marketListingKey={marketListingKey}
        mediaCommentId={selectedMediaCommentId}
        open={openSignUpOnPageModal}
        onClose={() => setOpenSignUpOnPageModal(false)}
      />
    </div>
  );
};
