import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import Typography from './typography';

export interface PaginationProps {
  compact?: boolean;
  currentPage: number;
  displayText?: boolean;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  rowsPerPage: number;
  setCurrentPage(page: number): void;
  totalCount: number;
}

export const Pagination: React.ComponentType<PaginationProps> = ({
  compact,
  currentPage,
  displayText = true,
  marginPagesDisplayed,
  pageRangeDisplayed,
  rowsPerPage,
  setCurrentPage,
  totalCount,
}) => {
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const pageIndex = currentPage - 1;

  return (
    <div
      className={clsx(
        'flex items-center gap-4',
        compact ? 'justify-end' : 'justify-end sm:justify-between'
      )}
    >
      {displayText && (
        <>
          <Typography
            className={clsx(
              'font-system text-hubs-primary',
              !compact && 'sm:hidden'
            )}
          >
            {totalCount === 0
              ? '0 results'
              : `${pageIndex * rowsPerPage + 1}-${Math.min(
                  (pageIndex + 1) * rowsPerPage,
                  totalCount
                )} of ${totalCount} result${totalCount === 1 ? '' : 's'}`}
          </Typography>
          <Typography
            className={clsx(
              'font-system text-hubs-primary',
              compact ? 'hidden' : 'hidden sm:block'
            )}
          >
            {'Showing '}
            <span className="font-semibold">
              {Math.min(pageIndex * rowsPerPage + 1, totalCount)}
            </span>
            {' to '}
            <span className="font-semibold">
              {Math.min((pageIndex + 1) * rowsPerPage, totalCount)}
            </span>
            {' of '}
            <span className="font-semibold">
              {`${totalCount} result${totalCount === 1 ? '' : 's'}`}
            </span>
          </Typography>
        </>
      )}

      <ReactPaginate
        activeLinkClassName="bg-hubs-background-accent font-semibold text-hubs-primary"
        breakClassName={compact ? 'hidden' : 'hidden sm:block'}
        breakLinkClassName="flex justify-center items-center w-[42px] h-[38px] typography-subtitle-2 font-system text-hubs-primary"
        containerClassName="inline-flex rounded border border-secondary-grey-light divide-x divide-secondary-grey-light"
        disabledLinkClassName="cursor-not-allowed"
        forcePage={pageCount > 0 ? pageIndex : undefined}
        marginPagesDisplayed={marginPagesDisplayed}
        nextLabel={<ChevronRightIcon className="size-5" />}
        nextLinkClassName="flex justify-center items-center w-[42px] h-[38px] text-hubs-primary font-system"
        pageClassName={compact ? 'hidden' : 'hidden sm:block'}
        pageCount={pageCount}
        pageLinkClassName="flex justify-center items-center w-[42px] h-[38px] typography-subtitle-2 text-hubs-primary font-normal hover:bg-hubs-background-accent transition-all font-system"
        pageRangeDisplayed={pageRangeDisplayed}
        previousLabel={<ChevronLeftIcon className="size-5" />}
        previousLinkClassName="flex justify-center items-center w-[42px] h-[38px] text-hubs-primary font-system"
        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
      />
    </div>
  );
};

export const StartCursor = Buffer.from('arrayconnection:-1').toString('base64');

export function calculateStartCursor(
  page: number,
  rowsPerPage: number,
  setStartCursor: React.Dispatch<React.SetStateAction<string>>
) {
  const nextCursor = rowsPerPage * (page - 1) - 1;
  setStartCursor(
    Buffer.from(`arrayconnection:${nextCursor}`).toString('base64')
  );
}

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'AUD':
      return '$';
    case 'GBX':
    default:
      return '';
  }
}

export default Pagination;
