import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface CheckboxProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  colour?: string;
}

export const Checkbox: React.ComponentType<CheckboxProps> = ({
  colour,
  ...props
}) => {
  const color = colour || 'white';

  return (
    <>
      <style jsx>{`
        input[type='checkbox']:checked {
          background-image: url('data:image/svg+xml,${encodeURIComponent(`
            <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1.25 5.375L4.25 8.375L8.75 1.625' stroke='${color}' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/>
            </svg>
          `)}');
          background-size: 60%;
          background-position: center;
          background-repeat: no-repeat;
        }
      `}</style>
      <input
        className="checkbox h-4 w-4 text-company-primary checked:bg-company-primary"
        type="checkbox"
        {...props}
      />
    </>
  );
};

export default Checkbox;
