import { useState } from 'react';
import analytics from '@analytics';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSubscribeMutation } from '../../../apollo/generated';
import Button, { ButtonColor } from '../../button';
import { useAlert } from '../../context/alert-context';
import { Typography } from '../../typography';

interface FormData {
  email: string;
  firstName?: string;
  lastName?: string;
}

interface SubscribeFormProps {
  buttonColor?: ButtonColor;
  event?: string;
  hasCustomText?: boolean;
  isSimpleForm?: boolean;
  listingKey?: string;
  marketKey?: string;
}

export const SubscribeForm: React.ComponentType<SubscribeFormProps> = ({
  buttonColor = 'black',
  event,
  hasCustomText,
  isSimpleForm,
  listingKey,
  marketKey,
}) => {
  const { formState, handleSubmit, register } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().trim().email().required(),
        firstName: yup.string().trim(),
        lastName: yup.string().trim(),
      })
    ),
  });

  const [showForm, toggleShowForm] = useState(true);

  const { formatAndShowError, showAlert } = useAlert();

  const [subscribe, { loading }] = useSubscribeMutation();

  const onSubmit = handleSubmit(async (data) => {
    if (event) analytics.track(event);

    subscribe({
      variables: {
        input: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
    })
      .then(() => {
        toggleShowForm(false);
        showAlert({
          description: 'Great, you are on the list to receive email updates',
          variant: 'success',
        });
      })
      .catch(formatAndShowError);
  });

  const isSunrice = marketKey === 'asx' && listingKey === 'sgl';

  return (
    <form className="bg-transparent" onSubmit={onSubmit}>
      <div>
        {showForm ? (
          <div className="space-y-4">
            <div
              className={clsx(
                'items-center gap-4',
                isSimpleForm
                  ? 'flex flex-col sm:flex-row'
                  : 'grid grid-cols-1 sm:grid-cols-10'
              )}
            >
              {!isSimpleForm && (
                <>
                  <input
                    className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary sm:col-span-2"
                    placeholder="First name"
                    type="text"
                    {...register('firstName')}
                  />
                  <input
                    className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary sm:col-span-2"
                    placeholder="Last name"
                    type="text"
                    {...register('lastName')}
                  />
                </>
              )}
              <div className="col-span-1 w-full sm:col-span-4 sm:max-w-[544px]">
                <input
                  className={clsx(
                    'input h-10 min-w-0 flex-1 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary',
                    !!formState.errors.email && 'input-error'
                  )}
                  placeholder="Email"
                  type="email"
                  {...register('email')}
                />
              </div>
              <div
                className={clsx(
                  isSimpleForm ? 'w-full sm:w-auto' : 'col-span-1'
                )}
              >
                <Button
                  className="w-full"
                  color={buttonColor}
                  disabled={formState.isSubmitting || loading}
                  type="submit"
                >
                  Subscribe
                </Button>
              </div>
            </div>
            {!hasCustomText && (
              <div className="flex items-center gap-x-2 md:self-stretch">
                <Typography variant="body-small">
                  {isSunrice ? (
                    <>
                      By submitting your email address, you consent to the
                      collection, use and disclosure of your personal
                      information by SunRice and our IT partner InvestorHub in
                      accordance with the{' '}
                      <a
                        className="underline"
                        href="https://res.cloudinary.com/investorhub/image/upload/v1731619070/leaf-prod/283/manual_uploads/sunrice-investorhub-collection-statement.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Collection Statement
                      </a>
                      . SunRice and InvestorHub collect personal information
                      from you when you interact with the SunRice Investor Hub
                      (through cookies and similar technologies), answer
                      questions, surveys or submit forms and other information.
                      This includes information collected from or about the
                      device you use, including IP address, webpages you have
                      visited and your browsing behaviour. We use your
                      information for the purposes set out in the Collection
                      Statement, which includes to improve user experience,
                      create user profiles and deliver tailored marketing
                      content to you. Please see{' '}
                      <a
                        className="underline"
                        href="https://www.sunrice.com.au/au-privacy-policy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        SunRice&apos;s Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        className="underline"
                        href="https://investorhub.com/au-microsite-privacy-policy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        InvestorHub&apos;s Privacy Policy
                      </a>{' '}
                      for information about how to access, correct or submit a
                      privacy complaint about your personal information. Your
                      emails are managed by InvestorHub, an investor engagement
                      platform. Unsubscribe anytime.
                    </>
                  ) : (
                    <>
                      Subscribe Emails are managed by InvestorHub. You can
                      unsubscribe at any time.
                    </>
                  )}
                </Typography>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </form>
  );
};
