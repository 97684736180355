import { useState, useEffect, RefObject } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createEvent } from 'ics';
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export function getLocalDate(
  utcDateTime: string | null | undefined,
  timezone: string | null | undefined
) {
  if (!utcDateTime || !timezone) {
    return dayjs().local();
  }

  return dayjs.utc(utcDateTime).tz(dayjs.tz.guess());
}

export const getWebinarBroadcastDuration = (
  currentTime: Date,
  liveTime: Date
) => {
  const diffInSeconds = dayjs(currentTime).diff(liveTime, 'second');
  return dayjs.duration(diffInSeconds, 'seconds').format('HH:mm:ss');
};

/*
  Returns the time remaining until the webinar starts.
  Usage:
    const timeRemaining = useTimeRemaining(webinar.startTime, webinar.timezone);
    e.g. { days: 0, hours: 0, minutes: 10, seconds: 20, total: 620000 }
*/
export const useTimeRemaining = (
  startTime: string,
  timeZone: string | null | undefined
) => {
  const [timeRemaining, setTimeRemaining] = useState(() =>
    getTimeRemaining(startTime, timeZone)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining(startTime, timeZone));
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime, timeZone]);

  return timeRemaining;
};

/*
  Allows you to give a video element a fullscreen button.

  Usage:
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const { isFullscreen, toggleFullscreen } = useFullscreen(videoContainerRef);
*/
export const useFullscreen = (elementRef: RefObject<HTMLElement>) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!elementRef.current) return;

    const elem = elementRef.current;

    // @ts-expect-error - this is for Safari (typescript doesn't know about it)
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // @ts-expect-error - this is for Safari (typescript doesn't know about it)
      } else if (elem.webkitRequestFullscreen) {
        // @ts-expect-error - this is for Safari (typescript doesn't know about it)
        elem.webkitRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-expect-error - this is for Safari (typescript doesn't know about it)
      } else if (document.webkitExitFullscreen) {
        // @ts-expect-error - this is for Safari (typescript doesn't know about it)
        document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        // @ts-expect-error - this is for Safari (typescript doesn't know about it)
        !!document.fullscreenElement || !!document.webkitFullscreenElement
      );
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
    };
  }, []);

  return { isFullscreen, toggleFullscreen };
};

export const getTimeRemaining = (
  startTime: string,
  timeZone: string | null | undefined
) => {
  const startInTz = getLocalDate(startTime, timeZone);
  const startUtc = startInTz.utc();
  const nowUtc = dayjs().utc();

  const total = startUtc.diff(nowUtc);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  return { days, hours, minutes, seconds, total };
};

export const formatDuration = (
  durationHours: number,
  durationMinutes: number
) => {
  // Convert hours to minutes and add to existing minutes
  const totalMinutes = durationHours * 60 + durationMinutes;
  return `${totalMinutes} minute${totalMinutes !== 1 ? 's' : ''}`;
};

export const formatTimeRemaining = (
  seconds: number,
  noTimeRemainingText?: string
) => {
  if (seconds <= 0) return noTimeRemainingText || '';

  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (days > 0) {
    return hours === 0
      ? `${days} day${days > 1 ? 's' : ''}`
      : `${days} day${days > 1 ? 's' : ''}, ${hours} hour${
          hours > 1 ? 's' : ''
        }`;
  }
  if (hours > 0) {
    return minutes === 0
      ? `${hours} hour${hours > 1 ? 's' : ''}`
      : `${hours} hour${hours > 1 ? 's' : ''}, ${minutes} minute${
          minutes > 1 ? 's' : ''
        }`;
  }
  return `${minutes} minute${minutes > 1 ? 's' : ''}, ${secs} second${
    secs > 1 ? 's' : ''
  }`;
};

export const createCalendarEvent = ({
  endTime,
  onError,
  onSuccess,
  startTime,
  timezone,
  title,
}: {
  endTime: string;
  onError: () => void;
  onSuccess: (value: string) => void;
  startTime: string;
  timezone: string | null | undefined;
  title: string;
}) => {
  const startInTz = getLocalDate(startTime, timezone);
  const endInTz = getLocalDate(endTime, timezone);

  createEvent(
    {
      end: [
        endInTz.year(),
        endInTz.month() + 1,
        endInTz.date(),
        endInTz.hour(),
        endInTz.minute(),
      ],
      endInputType: 'local',
      location: 'Online Webinar',
      start: [
        startInTz.year(),
        startInTz.month() + 1,
        startInTz.date(),
        startInTz.hour(),
        startInTz.minute(),
      ],
      startInputType: 'local',
      title: title || '',
    },
    (error, value) => {
      if (error) {
        onError();
        return;
      }
      onSuccess(value);
    }
  );
};
