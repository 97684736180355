import { useEffect, useState } from 'react';
import { useSignUpOnPage } from '../../../../context/sign-up-on-page-context';
import {
  CurrentInvestorUserUsernameQuery,
  Typography,
  useCurrentInvestorUserUsernameLazyQuery,
} from '../../../../index';

const SignUpLiteGoogleAuth: React.ComponentType = () => {
  const { complete, setStep, setUser } = useSignUpOnPage();

  const [usernameQuery] = useCurrentInvestorUserUsernameLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [externalPopup, setExternalPopup] = useState<Window | null>(null);

  const redirectPath = '/auth/oauth-success';

  const authUrl = `${
    process.env.NEXT_PUBLIC_API_URI
  }/auth/google?referer=${encodeURIComponent(window.location.origin)}${
    redirectPath ? `&redirect_path=${encodeURIComponent(redirectPath)}` : ''
  }`;

  const login = () => {
    const w = 500;
    const h = 400;
    const left = window.screenX + (window.outerWidth - w) / 2;
    const top = window.screenY + (window.outerHeight - h) / 2.5;
    const title = `Sign in with Google`;
    const url = authUrl;
    const popup = window.open(
      url,
      title,
      `width=${w},height=${h},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const signInCallback = (
      data: CurrentInvestorUserUsernameQuery | undefined
    ) => {
      externalPopup.close();
      if (data?.currentInvestorUser?.username) {
        complete();
      } else {
        setUser({
          email: data?.currentInvestorUser?.email || '',
          firstName: data?.currentInvestorUser?.firstName || '',
        });
        setStep('username');
      }
    };

    const timer = setInterval(async () => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentPath = externalPopup.location.pathname;

      if (!currentPath) {
        return;
      }
      if (currentPath === redirectPath) {
        await usernameQuery().then(({ data }) => signInCallback(data));
      }
      if (currentPath === '/auth/oauth-failure') {
        externalPopup.close();
      }
    }, 5000);
  }, [externalPopup, usernameQuery, complete, setStep, setUser]);

  return (
    <button
      className="mx-auto flex max-w-full flex-1 items-center gap-3 rounded-lg border  bg-hubs-background p-3 text-hubs-primary shadow-md sm:max-w-[240px]"
      type="button"
      onClick={login}
    >
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
          fill="#FBC02D"
        />
        <path
          d="M3.15308 7.3455L6.43858 9.755C7.32758 7.554 9.48058 6 12.0001 6C13.5296 6 14.9211 6.577 15.9806 7.5195L18.8091 4.691C17.0231 3.0265 14.6341 2 12.0001 2C8.15908 2 4.82808 4.1685 3.15308 7.3455Z"
          fill="#E53935"
        />
        <path
          d="M11.9999 21.9999C14.5829 21.9999 16.9299 21.0114 18.7044 19.4039L15.6094 16.7849C14.6054 17.5454 13.3574 17.9999 11.9999 17.9999C9.39891 17.9999 7.19041 16.3414 6.35841 14.0269L3.09741 16.5394C4.75241 19.7779 8.11341 21.9999 11.9999 21.9999Z"
          fill="#4CAF50"
        />
        <path
          d="M21.8055 10.0415L21.7975 10H21H12V14H17.6515C17.2555 15.1185 16.536 16.083 15.608 16.7855C15.6085 16.785 15.609 16.785 15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
          fill="#1565C0"
        />
      </svg>
      <Typography variant="subtitle-2">Sign up with Google</Typography>
    </button>
  );
};

export default SignUpLiteGoogleAuth;
