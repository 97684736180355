import { useCallback, useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { ArrowRightIcon, MegaphoneIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Button,
  MediaUpdateAttachmentType,
  MediaUpdateInBlock,
  RichContent,
  Typography,
  useReactToMediaCommentMutation,
  useUpsertFollowMutation,
} from '../../..';
import { useAlert } from '../../../context/alert-context';
import { AttemptedAction } from '../../../context/sign-up-on-page-context';
import CloudinaryImage from '../../ui/cloudinary-image';
import { QuestionAskedOn, QuestionAuthor } from './authors/question-author';
import { InvestorMediaCommentInBlock } from './block';
import FollowButton from './follow-button';
import LikeAnimation from './like-animation';

interface Props {
  companyName: string;
  hasChild?: boolean;
  investorMediaComment: InvestorMediaCommentInBlock;
  isFullySetup: boolean;
  isSignedIn: boolean;
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  setAttemptedAction: React.Dispatch<
    React.SetStateAction<AttemptedAction | undefined>
  >;
  setOpenSignUpOnPageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMediaCommentId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const InvestorQasBlockCardItem: React.ComponentType<Props> = ({
  companyName,
  hasChild,
  investorMediaComment,
  isFullySetup,
  isSignedIn,
  listingKey,
  logoUrl,
  marketKey,
  setAttemptedAction,
  setOpenSignUpOnPageModal,
  setSelectedMediaCommentId,
}) => {
  const router = useRouter();

  const hasReply =
    hasChild ??
    (investorMediaComment.children && investorMediaComment.children?.length > 0
      ? true
      : false);

  const { formatAndShowError, showAlert } = useAlert();

  const [localLiked, setLocalLiked] = useState(investorMediaComment.liked);
  const [liked, setLiked] = useState(investorMediaComment.liked);

  const [isOpenUnfollowModal, setIsOpenUnfollowModal] = useState(false);

  useEffect(() => {
    if (investorMediaComment.liked !== liked && !localLiked) {
      setLiked(investorMediaComment.liked);
      setLocalLiked(false);
    }
  }, [liked, investorMediaComment.liked, localLiked]);

  const likeCount = useCallback(() => {
    if (liked && !investorMediaComment.liked) {
      return (investorMediaComment.likes ?? 0) + 1;
    }
    if (!liked && investorMediaComment.liked) {
      return (investorMediaComment.likes ?? 0) - 1;
    }

    return investorMediaComment.likes ?? 0;
  }, [liked, investorMediaComment.liked, investorMediaComment.likes]);

  // reactToMediaComment Mutation
  const [reactToMediaComment, { loading: reactToMediaCommentLoading }] =
    useReactToMediaCommentMutation({
      awaitRefetchQueries: true,
      refetchQueries: ['InvestorMediaComments', 'CurrentInvestorUser'],
    });

  // upsertFollow Mutation
  const [upsertFollow, { loading: loadingFollow }] = useUpsertFollowMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'MediaFollow',
      'InvestorMediaComments',
      'CurrentInvestorUser',
    ],
  });

  const onLikeMediaComment = () => {
    if (!isFullySetup) {
      setSelectedMediaCommentId(investorMediaComment.id);
      setAttemptedAction('like-question');
      setOpenSignUpOnPageModal(true);
    } else {
      reactToMediaComment({
        variables: {
          like: !liked,
          mediaCommentId: investorMediaComment.id,
        },
      }).then(() => {
        setLiked((prev) => !prev);
        setLocalLiked(true);
      });
    }
  };

  const onFollowMediaComment = () => {
    if (!isFullySetup) {
      setSelectedMediaCommentId(investorMediaComment.id);
      setAttemptedAction('follow-question');
      setOpenSignUpOnPageModal(true);
    } else {
      upsertFollow({
        variables: {
          follow: {
            followingMediaCommentId: investorMediaComment.id,
            invalidated: investorMediaComment.followed,
          },
        },
      })
        .then(() => {
          setIsOpenUnfollowModal(false);

          if (investorMediaComment.followed) {
            showAlert({
              description: 'Unfollowed',
              variant: 'success',
            });
          }
        })
        .catch(formatAndShowError);
    }
  };

  const isAnnouncement = !!investorMediaComment.media?.mediaAnnouncement?.id;

  const title = isAnnouncement
    ? investorMediaComment.media?.mediaAnnouncement?.header ?? ''
    : investorMediaComment.media?.mediaUpdate?.title ?? '';

  const getMediaUpdateThumbnailImageUrl = (
    mediaUpdate?: MediaUpdateInBlock | null
  ) => {
    if (mediaUpdate) {
      switch (mediaUpdate?.thumbnailAttachment?.type) {
        case MediaUpdateAttachmentType.Image:
          return mediaUpdate.thumbnailAttachment.url;

        case MediaUpdateAttachmentType?.Video:
          return mediaUpdate.thumbnailAttachment.thumbnailUrl;

        case MediaUpdateAttachmentType?.Pdf:
          return mediaUpdate.thumbnailAttachment.thumbnail;

        default:
          return null;
      }
    }
    return null;
  };

  const thumbnailUrl = isAnnouncement
    ? investorMediaComment.media?.mediaAnnouncement?.thumbnailUrl
    : getMediaUpdateThumbnailImageUrl(investorMediaComment.media?.mediaUpdate);

  const questionAskedOn: QuestionAskedOn = {
    href: isAnnouncement
      ? `/announcements/${
          investorMediaComment.media?.mediaAnnouncement?.id ?? ''
        }`
      : `/activity-updates/${
          investorMediaComment.media?.mediaUpdate?.slug ?? ''
        }`,
    label: title,
  };

  const liveCommentUrl = useMemo(() => {
    return `${questionAskedOn.href}${
      investorMediaComment.annotationMetadata
        ? `?annotationId=${investorMediaComment.id}#interactive-pdf`
        : `#question-${investorMediaComment.id}`
    }`;
  }, [
    questionAskedOn.href,
    investorMediaComment.annotationMetadata,
    investorMediaComment.id,
  ]);

  const renderThumbnailImage = () => {
    if (thumbnailUrl) {
      return (
        <Image
          alt={title}
          className="h-[165px] w-full rounded-lg"
          height={165}
          src={thumbnailUrl}
          style={{ objectFit: 'cover' }}
          width={300}
        />
      );
    }

    return (
      <div className="flex h-[165px] w-full items-center justify-center rounded-lg bg-gray-100 ring-1 ring-inset ring-gray-200">
        {logoUrl ? (
          <CloudinaryImage alt="Logo" height={64} src={logoUrl} width={64} />
        ) : (
          <MegaphoneIcon className="h-16 w-16 fill-inherit" />
        )}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        'min-h-[485px] space-y-6 rounded-lg border border-secondary-grey-light p-4'
      )}
    >
      <div className="relative space-y-6">
        <div className="flex gap-4">
          <Link
            href={
              investorMediaComment.investorUser?.username
                ? `/profile/${investorMediaComment.investorUser.username}`
                : ''
            }
          >
            <div className="h-12 w-12">
              <div className="flex h-12 w-12 items-center justify-center rounded-full border border-company-accent-text bg-company-accent align-middle text-lg uppercase text-company-accent-text">
                {investorMediaComment.investorUser?.username?.at(0) ?? 'U'}
              </div>
            </div>
          </Link>
          <div className="space-y-1">
            <QuestionAuthor
              companyName={companyName}
              listingKey={listingKey}
              logoUrl={logoUrl}
              marketKey={marketKey}
              question={investorMediaComment}
            />
            <Typography className="text-hubs-primary" variant="fine-print">
              {dayjs(investorMediaComment.insertedAt).format(
                'MMMM DD, YYYY - hh:mmA'
              )}
            </Typography>
          </div>
        </div>

        {questionAskedOn && (
          <div className="flex items-center gap-2">
            <Link
              className="truncate font-body font-medium text-hubs-primary underline hover:opacity-80"
              href={questionAskedOn.href}
            >
              {`Re: ${questionAskedOn?.label || ''}`}
            </Link>
          </div>
        )}

        <div className="line-clamp-3">
          <RichContent
            content={investorMediaComment.content.replaceAll(
              'typography-body-regular',
              ''
            )}
          />
        </div>

        {renderThumbnailImage()}

        <div className="flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <button
              className="flex items-center gap-1 rounded-full bg-white px-2 py-1 text-hubs-fixed-dark hover:bg-gray-100"
              disabled={reactToMediaCommentLoading}
              onClick={onLikeMediaComment}
            >
              <LikeAnimation
                liked={liked}
                loadingLike={reactToMediaCommentLoading}
              />
              {likeCount() > 0
                ? Intl.NumberFormat('en').format(likeCount())
                : null}
            </button>

            <FollowButton
              buttonLoading={loadingFollow}
              buttonVariant="primary"
              className="!rounded-full border-white hover:border-hubs-dividers"
              color="white"
              companyName={companyName}
              displayText={false}
              followedId={investorMediaComment.id}
              isFollow={investorMediaComment.followed}
              isOpenUnfollowModal={isOpenUnfollowModal}
              setIsOpenUnfollowModal={setIsOpenUnfollowModal}
              size="xs"
              variant="question"
              onFollow={onFollowMediaComment}
            />
          </div>
          <Button
            className="text-company-primary"
            endIcon={<ArrowRightIcon className="h-4 w-4" />}
            size="sm"
            variant="tertiary"
            onClick={() => {
              router.push(liveCommentUrl);
              analytics.track(
                `${
                  hasReply ? 'view_company_reply' : 'view_question'
                }_button_on_investor_qas_block_clicked`,
                {
                  comment_id: investorMediaComment.id,
                  feature: 'investor_qas_block',
                }
              );
            }}
          >
            {hasReply ? 'View company reply' : 'View question'}
          </Button>
        </div>
      </div>
    </div>
  );
};
