import { ClockIcon } from '@heroicons/react-v2/24/outline';
import { FireIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import { Typography } from '../../..';

export enum tabItem {
  Popular = 'popular',
  Recent = 'recent',
}

interface Props {
  selectedTab: tabItem;
  setSelectedTab: React.Dispatch<React.SetStateAction<tabItem>>;
}

export const InvestorQasBlockTabs: React.ComponentType<Props> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const items = [
    {
      active: selectedTab === tabItem.Popular,
      icon: <FireIcon className="h-6 w-6" />,
      onClick: () => {
        setSelectedTab(tabItem.Popular);
      },
      title: 'Popular',
    },
    {
      active: selectedTab === tabItem.Recent,
      icon: <ClockIcon className="h-6 w-6" />,
      onClick: () => {
        setSelectedTab(tabItem.Recent);
      },
      title: 'Most Recent',
    },
  ];
  return (
    <div className="px-4 md:px-0">
      <div className="flex flex-wrap items-center justify-between gap-8 border-b border-b-secondary-grey-light pb-4 md:pb-0">
        <div className="mb-[-1.5px] flex items-center gap-8 pt-4">
          {items.map((item) => (
            <button
              key={item.title}
              className={clsx(
                'flex items-center gap-1 border-b-2 px-1 pb-4',
                item.active
                  ? 'border-b-company-primary text-company-primary'
                  : 'border-b-transparent text-text-grey hover:border-b-gray-300 hover:text-gray-700'
              )}
              type="button"
              onClick={item.onClick}
            >
              <div className="h-6 w-6">{item.icon}</div>
              <Typography noWrap variant="subtitle-1">
                {item.title}
              </Typography>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
