'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import clsx from 'clsx';
import Typography from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { HubButtonData, ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { HubButton } from '../ui/hub-button';
import { emitBlockClickEvent } from '../utils';

export interface HeaderBlockProps {
  blockId?: string; // Add this line
  content: {
    background?: 'none' | 'image' | 'primaryThemeColor' | 'accentThemeColor';
    backgroundImage?: ImageMeta | null;
    darkenBackgroundImage?: boolean;
    description?: Block[] | string;
    heading?: string;
    primaryButton?: HubButtonData;
    variant?: 'left_aligned' | 'center_aligned';
  };
  hubBuilderUrl?: string;
  index?: number;
  nextBlockType?: string | undefined | null;
  removeBottomSpacing?: boolean;
  removeTopSpacing?: boolean;
}

const PLACEHOLDER_HEADING = 'Please add a heading';

export const HeaderBlock: React.ComponentType<HeaderBlockProps> = ({
  blockId,
  content,
  hubBuilderUrl,
  index,
  nextBlockType,
  removeBottomSpacing,
  removeTopSpacing,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');

  const editor = useCreateBlockNote();

  const getPrimaryButtonUrl = (url: string) => {
    if (url === '/') {
      return hubBuilderUrl ? hubBuilderUrl + '/home' : '/';
    }

    if (url.startsWith('/')) {
      return (hubBuilderUrl ?? '') + url;
    }

    return url;
  };

  useEffect(() => {
    if (content.description) {
      if (typeof content.description === 'string') {
        const contentDescriptionHTML = content.description.replace(
          /\n/g,
          '<br />'
        );

        setDescriptionHTML(contentDescriptionHTML);
      } else {
        const convertDescriptionToHTML = async () => {
          const html = await editor.blocksToHTMLLossy(
            content.description as Block[]
          );
          if (html === '<p></p>') {
            setDescriptionHTML('');
          } else {
            setDescriptionHTML(html);
          }
        };

        convertDescriptionToHTML();
      }
    }
  }, [editor, content.description]);

  const getBgColorClass = () => {
    switch (content.background) {
      case 'primaryThemeColor':
        return 'bg-company-primary';
      case 'accentThemeColor':
        return 'bg-company-accent';
      default:
        return 'bg-hubs-background';
    }
  };

  const getTextColorClass = () => {
    switch (content.background) {
      case 'primaryThemeColor':
        return 'text-company-primary-text prose-headings:text-company-primary-text prose-p:text-company-primary-text prose-a:text-company-primary-text prose-strong:text-company-primary-text prose-table:text-company-primary-text marker:text-company-primary-text';
      case 'accentThemeColor':
        return 'text-company-accent-text prose-headings:text-company-accent-text prose-p:text-company-accent-text prose-a:text-company-accent-text prose-strong:text-company-accent-text prose-table:text-company-accent-text marker:text-company-accent-text';
      default:
        return 'text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary';
    }
  };

  // Can't just use none, as many blocks have no background set
  const isBgNoneOrUndefined = (content.background === 'none' ||
    !content.background) as boolean;

  return (
    <div
      className={clsx(
        'relative scroll-mt-[120px] py-16 sm:py-20',
        (removeTopSpacing || isBgNoneOrUndefined) && '-mt-16 md:-mt-20',
        // AS: Removes bottom spacing if a.) the next block is not a hero block
        // and b.) either removeBottomSpacing === true or the background is 'none'
        nextBlockType !== 'hero' &&
          (removeBottomSpacing || content.background === 'none') &&
          '-mb-16 md:-mb-20',
        content.background === 'image'
          ? content.backgroundImage
            ? ''
            : 'bg-gray-900' // give a dark bg when no image is set, as the overlaid text is white
          : getBgColorClass()
      )}
      id="header_block"
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.background === 'image' && content.backgroundImage && (
        <div className="absolute inset-0 h-full w-full">
          {content.darkenBackgroundImage === true && (
            <div className="absolute inset-0 z-[2] bg-black/40" />
          )}
          <CloudinaryImage
            alt={content.heading ?? ''}
            className="absolute inset-0 z-[1] h-full w-full object-cover"
            fill={true}
            priority={index === 0}
            sizes="100vw"
            src={content.backgroundImage.cloudinaryUrl || ''}
          />
        </div>
      )}
      <div className={`${BLOCK_WIDTH_CLASS} relative z-[3]`}>
        <div
          className={clsx(
            'mx-auto flex max-w-4xl flex-col gap-6',
            content.variant === 'center_aligned' ? 'text-center' : 'lg:mx-0'
          )}
        >
          <Typography
            className={clsx(
              'font-heading text-3xl font-semibold md:text-5xl',
              content.variant === 'center_aligned'
                ? 'text-center'
                : 'text-left',
              content.background === 'image'
                ? 'text-white'
                : getTextColorClass()
            )}
            variant="heading-2"
          >
            {content.heading ?? PLACEHOLDER_HEADING}
          </Typography>
          {descriptionHTML && (
            <div
              dangerouslySetInnerHTML={{ __html: descriptionHTML }}
              className={clsx(
                content.background === 'image'
                  ? 'text-white marker:text-white prose-headings:text-white prose-p:text-white prose-a:text-white prose-strong:text-white prose-table:text-white'
                  : content.background === 'none'
                  ? 'text-hubs-secondary marker:text-hubs-secondary prose-headings:text-hubs-secondary prose-p:text-hubs-secondary prose-a:text-hubs-secondary prose-strong:text-hubs-secondary prose-table:text-hubs-secondary'
                  : getTextColorClass(),
                content.variant === 'center_aligned'
                  ? 'text-center'
                  : 'text-left',
                'blocknote prose max-w-none list-inside list-disc font-body text-lg leading-normal'
              )}
            />
          )}
          {content.primaryButton?.label && (
            <div
              className={clsx(
                'z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row',
                content.variant === 'center_aligned'
                  ? 'justify-center'
                  : 'justify-normal'
              )}
            >
              <HubButton
                className="w-full justify-center md:w-auto"
                isAccent={content.background === 'primaryThemeColor'}
                isPrimary={content.background !== 'primaryThemeColor'}
                label={content.primaryButton?.label ?? ''}
                openInNewTab={
                  !getPrimaryButtonUrl(
                    content.primaryButton.url ?? '/'
                  ).startsWith('/')
                }
                size="lg"
                url={getPrimaryButtonUrl(content.primaryButton.url ?? '/')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
