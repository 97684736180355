import { useCallback, useEffect, useState } from 'react';
import {
  ApolloClient,
  NetworkStatus,
  NormalizedCacheObject,
} from '@apollo/client';
import { VideoCameraIcon } from '@heroicons/react/outline';
import {
  Skeleton,
  BlockHeading,
  WebinarBlockCard,
  useWebinarsQuery,
  StartCursor,
  calculateStartCursor,
  Button,
  Typography,
} from '../../../index';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    heading: string;
  };
  logoUrl: string;
  skip?: string;
}

export const WebinarBlock: React.ComponentType<Props> = ({
  client,
  content = { heading: 'Webinar' },
  logoUrl,
  skip,
}) => {
  const ROWS_PER_PAGE = 6;
  const [startCursor, setStartCursor] = useState(StartCursor);
  const { data, fetchMore, loading, networkStatus } = useWebinarsQuery({
    client,
    variables: {
      after: startCursor,
      first: ROWS_PER_PAGE,
      options: {
        filters: [skip ? { key: 'skip', value: skip } : {}],
        orders: [
          { key: 'start_time', value: 'desc' },
          { key: 'id', value: 'desc' },
        ],
      },
    },
  });

  useEffect(() => {
    calculateStartCursor(1, ROWS_PER_PAGE, setStartCursor);
  }, [ROWS_PER_PAGE]);

  const hasNextPage = !!data?.webinars?.pageInfo.hasNextPage;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.webinars?.pageInfo.endCursor,
        first: ROWS_PER_PAGE,
      },
    });
  }, [data, fetchMore, ROWS_PER_PAGE]);

  const renderWebinarCards = () => (
    <div className="flex flex-col gap-4">
      {content.heading ? <BlockHeading>{content.heading}</BlockHeading> : null}
      <div className="flex flex-col gap-4">
        {loading ? (
          <>
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
            <Skeleton loading height={316} variant="rect" />
          </>
        ) : data?.webinars?.edges?.length ? (
          <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-3">
            {data?.webinars?.edges?.map((edge) =>
              edge?.node ? (
                <WebinarBlockCard
                  key={`webinar-${edge.node.id}`}
                  logoUrl={logoUrl}
                  webinar={edge.node}
                />
              ) : null
            )}
            {hasNextPage && (
              <div className="pt-10">
                <Button
                  className="w-full md:w-auto"
                  disabled={isLoadingMore}
                  variant="primary"
                  onClick={onLoadMore}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="grid place-items-center gap-4 rounded-lg border p-8">
            <div className="h-12 w-12 rounded-full bg-company-accent p-3 text-company-primary">
              <VideoCameraIcon className="h-6 w-6 text-company-primary" />
            </div>
            <Typography className="text-center" variant="subtitle-1">
              No current webinars
            </Typography>
            <Typography className="text-center">
              Check back soon for upcoming webinars
            </Typography>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className="mx-auto w-full max-w-screen-xl space-y-6 px-4 md:px-6"
      id="webinars_block"
    >
      {renderWebinarCards()}
    </div>
  );
};
