import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CldVideoPlayer, CloudinaryVideoPlayer } from 'next-cloudinary';
import { formatVideoUrl } from '@leaf/helpers';
import { useCreateOrUpdateWebinarViewMutation } from '../../../apollo/generated';
import { useAlert } from '../../context/alert-context';

interface WebinarRecordingPlayerProps {
  attendeeId?: string;
  posterImageUrl?: string | null;
  publishedRecordingUrl: string;
  visitorCookieId: string;
  webinarId: string;
}

export const WebinarRecordingPlayer: React.FC<WebinarRecordingPlayerProps> = ({
  attendeeId,
  posterImageUrl,
  publishedRecordingUrl,
  visitorCookieId,
  webinarId,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const cloudinaryPlayerRef = useRef<CloudinaryVideoPlayer | null>(null);
  const updateInterval = useRef<NodeJS.Timeout>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [cumulativeWatchSeconds, setCumulativeWatchSeconds] = useState(0);
  const { formatAndShowError } = useAlert();

  const [createOrUpdateWebinarView] = useCreateOrUpdateWebinarViewMutation();

  const isVimeoUrl = publishedRecordingUrl?.includes('vimeo.com');
  const isYoutubeUrl = publishedRecordingUrl?.includes('youtube.com');
  const isCloudinaryUrl = publishedRecordingUrl?.includes('res.cloudinary.com');
  const formattedSocialUrl = formatVideoUrl(publishedRecordingUrl);

  const getWebinarViewVariables = useCallback(
    (watchTimeSeconds: number) => ({
      variables: {
        attendeeId,
        isMobile: /Mobile|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
        userAgent: navigator.userAgent,
        visitorCookieId,
        watchTimeSeconds,
        webinarId,
      },
    }),
    [attendeeId, visitorCookieId, webinarId]
  );

  useEffect(() => {
    if (isPlaying) {
      updateInterval.current = setInterval(() => {
        setCumulativeWatchSeconds((prev) => prev + 1);
      }, 1000);
    } else {
      if (updateInterval.current) clearInterval(updateInterval.current);
    }

    return () => {
      if (updateInterval.current) clearInterval(updateInterval.current);
    };
  }, [isPlaying]);

  useEffect(() => {
    // Every 10 seconds, tell the server another 10 seconds was watched. The server will add this amount of time to the webinars_recording_views row, column watch_time_seconds.
    if (cumulativeWatchSeconds >= 10) {
      createOrUpdateWebinarView(getWebinarViewVariables(10)).catch(
        formatAndShowError
      );

      setCumulativeWatchSeconds(0);
    }
  }, [
    cumulativeWatchSeconds,
    webinarId,
    visitorCookieId,
    attendeeId,
    createOrUpdateWebinarView,
    formatAndShowError,
    getWebinarViewVariables,
  ]);

  const handleVideoPlay = useCallback(async () => {
    if (isPlaying) return;

    try {
      if (cumulativeWatchSeconds === 0) {
        await createOrUpdateWebinarView(getWebinarViewVariables(0));
      }

      setIsPlaying(true);
    } catch (error) {
      formatAndShowError(error);
    }
  }, [
    isPlaying,
    cumulativeWatchSeconds,
    createOrUpdateWebinarView,
    formatAndShowError,
    getWebinarViewVariables,
  ]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);
    const handlePlay = () => handleVideoPlay();

    video.addEventListener('pause', handlePause);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('play', handlePlay);

    return () => {
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('play', handlePlay);
    };
  }, [handleVideoPlay]);

  function renderVideo() {
    if (isVimeoUrl && formattedSocialUrl) {
      return (
        <iframe
          allowFullScreen
          className="aspect-video h-full w-full bg-black"
          src={formattedSocialUrl}
          title="webinar-video"
        />
      );
    }

    if (isYoutubeUrl && formattedSocialUrl) {
      return (
        <div className="flex h-full w-full items-center justify-center bg-black">
          <iframe
            allowFullScreen
            className="aspect-video w-full max-w-[1155px] bg-black"
            src={formattedSocialUrl}
            title="webinar-video"
          />
        </div>
      );
    }

    if (isCloudinaryUrl) {
      return (
        <div className="flex h-full w-full justify-center bg-black">
          <div className="h-[650px] w-[1155px]">
            <CldVideoPlayer
              height={1080}
              playerRef={cloudinaryPlayerRef}
              poster={posterImageUrl || ''}
              src={publishedRecordingUrl}
              width={1920}
              onEnded={() => setIsPlaying(false)}
              onPause={() => setIsPlaying(false)}
              onPlay={handleVideoPlay}
            />
          </div>
        </div>
      );
    }

    return (
      <video
        ref={videoRef}
        controls
        className="h-full w-full bg-black"
        poster={posterImageUrl || ''}
        src={publishedRecordingUrl}
      >
        Your browser does not support the video tag.
      </video>
    );
  }

  return <div className="relative h-[650px] w-full">{renderVideo()}</div>;
};
