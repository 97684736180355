import React from 'react';
import { MegaphoneIcon } from '@heroicons/react-v2/24/solid';
import { Button, Typography } from '../../../index';
import CloudinaryImage from '../../ui/cloudinary-image';

interface Props {
  companyName: string;
  logoUrl: string;
  setOpenSignUpOnPageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const JoinHubBlock: React.ComponentType<Props> = ({
  companyName,
  logoUrl,
  setOpenSignUpOnPageModal,
}) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-12 p-6">
      <div>
        {logoUrl ? (
          <CloudinaryImage
            alt="Logo"
            className="h-full w-full object-contain"
            height={64}
            src={logoUrl}
            width={64}
          />
        ) : (
          <MegaphoneIcon className="h-16 w-16 fill-inherit" />
        )}
      </div>
      <Typography className="text-center text-hubs-primary">
        {`Join ${companyName} today to ask your question and be part of our
        community!`}
      </Typography>
      <Button
        variant="primary"
        onClick={() => {
          setOpenSignUpOnPageModal(true);
        }}
      >
        Join
      </Button>
    </div>
  );
};

export default JoinHubBlock;
