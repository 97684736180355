import { ApolloError } from '@apollo/client';
import cookieParser from 'cookie';
import * as yup from 'yup';

export function formatError(error: unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.map((e) => e.message).join(' ');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Oops! Something went wrong.';
}

// these companies have low domain authorities so we don't want backlinks from them
const badDomains = new Set([
  'mvl',
  'knm',
  'hvy',
  'lvh',
  'pwn',
  'she',
  'rad',
  'bsx',
  'env',
  'chm',
  'akn',
  'lgm',
  'ssh',
  'rac',
  'atc',
  'ajq',
  'brk',
  'htg',
  'dre',
  'jll',
  'emu',
  'gsn',
  'shg',
  'dou',
  'ams',
  '3da',
  'dcc',
  'wa8',
  'agn',
  'caz',
  'hlx',
  'sp3',
  'ipt',
  'meu',
  'dmm',
  'esr',
  'gmn',
  'yrl',
  'tso',
  'mxr',
  'tem',
  'oau',
  'wzr',
  'aqd',
  'bre',
  'tgm',
  'fg1',
  'nnl',
  'fos',
  'dnk',
  'sw1',
  'tryb-l',
  'ree-l',
  'n4p-l',
  'alk-l',
  'kash-aq',
  'ana-aq',
]);

export const maybeAddRelNofollow = (marketListingKey: string) => {
  if (badDomains.has(marketListingKey)) {
    return 'nofollow';
  }
  return '';
};

export const passwordValidation = yup
  .string()
  .trim()
  .required('Required.\n')
  .min(6, 'Minimum 6 characters.\n')
  .max(72, 'Maximum 72 characters.\n')
  .matches(/[A-Z]/, 'At least 1 uppercase character.\n')
  .matches(/[0-9]/, 'At least 1 number.\n');

const HERMES_RETURN_TO_COOKIE = '_hermes_return_to';

const HERMES_RETURN_TO_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
};

export const setReturnToCookie = (pathname: string): void => {
  document.cookie = cookieParser.serialize(
    HERMES_RETURN_TO_COOKIE,
    pathname,
    HERMES_RETURN_TO_COOKIE_OPTIONS
  );
};
