import { Fragment, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { AdjustmentsHorizontalIcon } from '@heroicons/react-v2/24/outline';
import { Button, Typography } from '../../../index';
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const QuickFiltersMobile: React.ComponentType<
  PropsWithChildren<Props>
> = ({ children, open, setOpen }) => {
  return (
    <>
      <Button
        className="md:hidden"
        size="md"
        startIcon={<AdjustmentsHorizontalIcon className="h-5 w-5" />}
        type="button"
        variant="secondary"
        onClick={() => setOpen(true)}
      >
        Filters
      </Button>
      <Transition.Root as={Fragment} show={open}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-x-0 bottom-0 top-[72px] bg-primary-off-black/50 transition-opacity" />
          </Transition.Child>

          <div className="inset-x-0 bottom-0 top-[72px] overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed bottom-0 right-0 top-[72px] flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-[260px] sm:w-[300px]">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute -left-10 top-4 flex">
                        <button
                          className="rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white"
                          type="button"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon aria-hidden="true" className="size-5" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-auto bg-hubs-background">
                      <div className="flex-1 p-4 md:p-8">
                        <div className="flex items-center gap-1">
                          <Typography>Filters</Typography>
                        </div>

                        <div className="mt-4 h-px bg-secondary-grey-light" />

                        <div className="mt-4 flex flex-col items-start gap-4">
                          {children}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
