'use client';

import dynamic from 'next/dynamic';

/**
 * Dynamic import of the BlockNoteRenderer component
 * This is necessary to avoid server-side rendering issues
 * with the BlockNote editor.
 */
export const DynamicRenderBlockNote = dynamic(
  () => import('./render-block-note'),
  {
    ssr: false,
  }
);
