import { useEffect, useState } from 'react';
import analytics from '@analytics';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Checkbox, TextInput, Typography } from '../../../index';
import { formatError } from '../../../lib/utils';
import { useSignUpOnPage } from '../../context/sign-up-on-page-context';

interface FormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface Props {
  companyId: string;
  login(
    companyProfileId: string,
    email: string,
    password: string,
    rememberMe?: boolean,
    shareholderHashId?: string
  ): Promise<void>;
}

const SignInLite: React.ComponentType<Props> = ({ companyId, login }) => {
  useEffect(() => {
    analytics.track('signup_modal_sign_in_viewed');
  }, []);
  const [showPassword, togglePassword] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const { complete } = useSignUpOnPage();

  const { formState, handleSubmit, register, setError, setValue, watch } =
    useForm<FormData>({
      defaultValues: {
        email: '',
        password: '',
        rememberMe: true,
      },
      resolver: yupResolver(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .required('Required.')
            .email('Email is invalid.'),
          password: yup.string().trim().required('Required.'),
        })
      ),
    });

  const onSubmit = handleSubmit(async ({ email, password, rememberMe }) => {
    await login(companyId, email, password, rememberMe)
      .then(() => {
        analytics.track('irm_contact_activity_created');
        complete();
      })
      .catch((error) => {
        // When suspicious cloud ip is detected
        if (formatError(error) === 'Access denied!') {
          // Redirect them to any other page, which will show the error component
          window.location.assign('/announcements');
        }

        setError('email', { message: ' ' });
        setError('password', { message: ' ' });
        setFormError(
          'Oops! Your sign in details are incorrect. Please double-check your information and try again.'
        );
      });
  });

  return (
    <div className="mx-auto p-6 text-hubs-secondary sm:max-w-[476px] sm:rounded-lg sm:px-0 sm:pb-11 sm:pt-8">
      <form onSubmit={onSubmit}>
        <div className="mb-8 space-y-4">
          <div>
            <TextInput
              className="shadow-md"
              label="Email"
              placeholder="example@gmail.com"
              {...register('email')}
              autoComplete="email"
              error={!!formState.errors.email?.message}
              helperText={formState.errors.email?.message}
              id="email"
            />
          </div>
          <div>
            <TextInput
              className="shadow-md"
              label="Password"
              placeholder="*********"
              {...register('password')}
              autoComplete="current-password"
              error={!!formState.errors.password?.message}
              helperText={formState.errors.password?.message}
              id="password"
              trailingIcon={showPassword ? EyeOffIcon : EyeIcon}
              trailingIconAction={() => togglePassword(!showPassword)}
              type={showPassword ? 'text' : 'password'}
            />
          </div>

          {!!formError &&
            typeof formError === 'string' &&
            formError.length > 0 && (
              <div>
                <Typography className="text-status-red">{formError}</Typography>
              </div>
            )}

          <div className="my-4 flex items-center gap-2 py-1">
            <Checkbox
              checked={watch('rememberMe')}
              {...register('rememberMe', {
                onChange: (e) => setValue('rememberMe', e.target.checked),
              })}
              id="rememberMe"
            />
            <label htmlFor="rememberMe">
              <Typography component="span" variant="button">
                Remember me
              </Typography>
            </label>
          </div>
        </div>
        <div className="space-y-4">
          <Button
            fullWidth
            disabled={formState.isSubmitting}
            type="submit"
            onClick={() => analytics.track('signin_form_submitted')}
          >
            Log in
          </Button>
          <div className="text-center">
            <Link className="underline" href="/auth/reset-password">
              Forgot password?
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignInLite;
