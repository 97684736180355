import React from 'react';
import { AtSymbolIcon } from '@heroicons/react/outline';
import {
  CommentSource,
  BadgeV2,
  LinkedinIcon,
  TwitterIcon,
  Typography,
} from '../../../../index';

const CommentSourceBadge: React.FC<{ commentSource: CommentSource }> = ({
  commentSource,
}) => {
  switch (commentSource) {
    case CommentSource.Email:
      return (
        <BadgeV2 color="amber">
          <span className="flex items-center gap-1">
            <AtSymbolIcon className="h-3 w-3" /> Asked via email
          </span>
        </BadgeV2>
      );
    case CommentSource.Twitter:
      return (
        <BadgeV2 color="sky">
          <span className="flex items-center gap-1">
            <TwitterIcon className="h-3 w-3" /> Asked via Twitter
          </span>
        </BadgeV2>
      );
    case CommentSource.Linkedin:
      return (
        <BadgeV2 color="sky">
          <span className="flex items-center gap-1">
            <LinkedinIcon className="h-3 w-3" /> Asked via LinkedIn
          </span>
        </BadgeV2>
      );
    case CommentSource.Forum:
      return <BadgeV2 color="amber">Asked via Forum</BadgeV2>;
    case CommentSource.Other:
      return <BadgeV2 color="gray">Asked via other source</BadgeV2>;
    default:
      return null;
  }
};

const QuestionOtherSourceAuthor: React.FC<{
  commentSource: CommentSource;
}> = ({ commentSource }) => {
  return (
    <div className="flex flex-wrap items-center justify-between gap-2">
      <Typography
        className="typography-subtitle-1 break-all text-hubs-primary"
        variant="subtitle-1"
      >
        Anonymous User
      </Typography>
      <CommentSourceBadge commentSource={commentSource} />
    </div>
  );
};

export default QuestionOtherSourceAuthor;
