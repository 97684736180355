import { useCurrentInvestorUserQuery } from '../../../../index';
import { InvestorMediaCommentInBlock } from '../block';
import { QuestionCompanyAuthor } from './company-author';
import QuestionInvestorAuthor from './investor-author';
import QuestionOtherSourceAuthor from './other-source-author';

export interface QuestionAskedOn {
  href: string;
  icon?: React.ReactNode;
  label: string;
}

export const authorFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return '';
};

interface Props {
  companyName: string;
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  question: InvestorMediaCommentInBlock;
}

export const QuestionAuthor: React.ComponentType<Props> = ({
  companyName,
  listingKey,
  logoUrl,
  marketKey,
  question,
}) => {
  const { data: currentUserData } = useCurrentInvestorUserQuery();

  const renderAuthor = () => {
    if (question.investorUser) {
      return (
        <QuestionInvestorAuthor
          isUser={
            !!question.investorUser.id &&
            question.investorUser.id ===
              currentUserData?.currentInvestorUser?.id
          }
          showShareholder={question.investorUser.showShareholderBadge}
          userName={question.investorUser.username ?? ''}
        />
      );
    }

    if (question.companyAuthor) {
      return (
        <QuestionCompanyAuthor
          listingKey={listingKey}
          logoUrl={logoUrl}
          marketKey={marketKey}
          userName={
            question.useCompanyAsUsername
              ? companyName
              : authorFullName(
                  question.companyAuthor.firstName,
                  question.companyAuthor.lastName
                )
          }
        />
      );
    }

    if (question.commentSource) {
      return (
        <QuestionOtherSourceAuthor commentSource={question.commentSource} />
      );
    }

    return null;
  };

  return <div>{renderAuthor()}</div>;
};
