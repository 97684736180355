import React from 'react';
import { HermesGenericModal } from '../../../../index';

interface Props {
  investorName?: string;
  loading: boolean;
  onClose: () => void;
  open: boolean;
  unfollowButtonAction: () => void;
  variant: 'announcement' | 'update' | 'question' | 'user';
}

const UnfollowModal: React.ComponentType<Props> = ({
  investorName = 'this user',
  loading,
  onClose,
  open,
  unfollowButtonAction,
  variant,
}) => {
  const descriptionText = React.useMemo(() => {
    switch (variant) {
      case 'user':
        return `about ${investorName}'s activity (questions and likes) on the hub`;
      case 'question':
        return 'about this question';

      default:
        return `when there is new activity on this ${variant}`;
    }
  }, [investorName, variant]);

  return (
    <HermesGenericModal
      bottomModal
      headerDescriptionText={`You will no longer receive in-app notifications ${descriptionText}.`}
      headerText="Unfollow?"
      loading={loading}
      open={open}
      primaryButtonAction={unfollowButtonAction}
      primaryButtonText="Unfollow"
      secondaryButtonAction={onClose}
      secondaryButtonText="Cancel"
      onClose={onClose}
    />
  );
};

export default UnfollowModal;
