import { useEffect } from 'react';
import analytics from '@analytics';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Button, TextInput, Typography, Checkbox } from '../../../index';
import { maybeAddRelNofollow } from '../../../lib/utils';
import { useSignUpOnPage } from '../../context/sign-up-on-page-context';
import SignUpLiteGoogleAuth from './oauth/google/ button';

interface FormData {
  email: string;
  termsAndConditions: boolean;
  unsubscribe: boolean;
}

interface Props {
  companyName: string;
  isUK: boolean;
  listingKey: string;
  marketListingKey: string;
}

const SignUpLiteEmail: React.ComponentType<Props> = ({
  companyName,
  isUK,
  listingKey,
  marketListingKey,
}) => {
  useEffect(() => {
    analytics.track('signup_modal_enter_email_viewed');
  }, []);

  const { control, formState, handleSubmit, register, watch } =
    useForm<FormData>({
      defaultValues: {
        email: '',

        termsAndConditions: false,
        // When signing up to an investor hub, UK investors must opt-in to receive emails from the company whilst AU investors can be opt-out
        unsubscribe: isUK ? true : false,
      },
      resolver: yupResolver(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .required('Required.')
            .email('Email is invalid.'),
        })
      ),
    });

  const watchTermsAndConditions = watch('termsAndConditions');

  const { setStep, setUser } = useSignUpOnPage();

  const onSubmit = handleSubmit(({ email, unsubscribe }) => {
    setUser({ email, unsubscribe });
    setStep('details');
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-4 space-y-4">
          <div className="space-y-3">
            <TextInput
              {...register('email')}
              autoComplete="email"
              className="shadow-md"
              error={!!formState.errors.email?.message}
              helperText={formState.errors.email?.message}
              id="email"
              label="Email"
              type="email"
            />
          </div>
        </div>
        {/* Subscribe opt-in (UK) / opt-out (AU)*/}
        <div className="mt-8 flex items-center gap-x-2 self-stretch">
          <Controller
            control={control}
            name="unsubscribe"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={!value}
                id="field-unsubscribe"
                onChange={() => onChange(!value)}
              />
            )}
          ></Controller>
          <label htmlFor="field-unsubscribe">
            <Typography variant="button">
              I want to receive news and updates from {companyName}, which are
              managed and sent through InvestorHub
            </Typography>
          </label>
        </div>
        <div className="mt-8 flex items-center gap-x-2 self-stretch">
          <Controller
            control={control}
            name="termsAndConditions"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                id="field-termsAndConditions"
                onChange={() => onChange(!value)}
              />
            )}
          ></Controller>
          <label htmlFor="field-termsAndConditions">
            <Typography variant="button">
              Yes, I am over 18 years old, I agree to the{' '}
              <Link
                className="text-inherit underline"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-terms-and-conditions'
                    : 'https://investorhub.com/au-microsite-terms-and-conditions'
                }
                rel={`${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                terms and conditions
              </Link>
              , and I have read the{' '}
              <Link
                className="text-inherit underline"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-privacy-policy'
                    : 'https://investorhub.com/au-microsite-privacy-policy'
                }
                rel={`${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                privacy policy
              </Link>{' '}
              *
            </Typography>
          </label>
        </div>
        <div className="mt-8 space-y-8">
          <Button
            fullWidth
            disabled={formState.isSubmitting || !watchTermsAndConditions}
            testId="sign-up-btn"
            type="submit"
            onClick={() => analytics.track('signup_form_submitted')}
          >
            Create account
          </Button>
          <Typography align="center">
            Already have an account?{' '}
            <span
              className="cursor-pointer text-company-primary underline"
              onClick={() => {
                analytics.track('signin_from_signup_page_clicked');
                setStep('sign-in');
              }}
            >
              Log in
            </span>
          </Typography>
        </div>
      </form>
      {listingKey.toLowerCase() !== 't88' ? (
        <div className="mt-9 border-t pt-6">
          <SignUpLiteGoogleAuth />
        </div>
      ) : null}
    </>
  );
};

export default SignUpLiteEmail;
