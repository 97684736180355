import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react-v2/24/outline';
import Button from '../button';
import { CarouselButtonProps } from '.';

export const PrevButton: React.FC<CarouselButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
}) => {
  return (
    <Button
      className={className}
      disabled={disabled}
      endIcon={<ChevronLeftIcon className="h-5 w-5" />}
      size="sm"
      variant="primary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
