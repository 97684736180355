import { ApolloError } from '@apollo/client';
import cookieParser from 'cookie';
import { BLOCK_CLICKED_EVENT } from './constants';

// This is for when a user is in the hub builder and clicks on a block - it automatically loads the editor in the sidebar for that block
// Pairs with useBlockClickNavigation
export const emitBlockClickEvent = (blockId?: string) => {
  if (blockId) {
    // event must match the one in useBlockClickNavigation()
    const event = new CustomEvent(BLOCK_CLICKED_EVENT, { detail: blockId });
    window.dispatchEvent(event);
  }
};

const HERMES_RETURN_TO_COOKIE = '_hermes_return_to';

const HERMES_RETURN_TO_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
};

export const setReturnToCookie = (pathname: string): void => {
  document.cookie = cookieParser.serialize(
    HERMES_RETURN_TO_COOKIE,
    pathname,
    HERMES_RETURN_TO_COOKIE_OPTIONS
  );
};
export const getReturnToCookiePath = (): string | undefined => {
  const cookie = cookieParser.parse(document.cookie);

  return cookie[HERMES_RETURN_TO_COOKIE];
};
export const clearReturnToCookie = () => {
  document.cookie = cookieParser.serialize(HERMES_RETURN_TO_COOKIE, '', {
    ...HERMES_RETURN_TO_COOKIE_OPTIONS,
    maxAge: 0,
  });
};

export function formatError(error: unknown) {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.map((e) => e.message).join(' ');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Oops! Something went wrong.';
}

// This one is for generating the random background color for user's avatar, similar with Google. But CSM wanted to use the company secondary colors instead.
// Comment out this function because Bayu wanted to keep this for future use. :)
// export function generateCustomBgColorFromPalette(id: number) {
//   const colors = [
//     'bg-red-600',
//     'bg-orange-600',
//     'bg-amber-600',
//     'bg-yellow-600',
//     'bg-lime-600',
//     'bg-green-600',
//     'bg-emerald-600',
//     'bg-teal-600',
//     'bg-cyan-600',
//     'bg-sky-600',
//     'bg-blue-600',
//     'bg-indigo-600',
//     'bg-violet-600',
//     'bg-purple-600',
//     'bg-fuchsia-600',
//     'bg-rose-600',
//   ];

//   const pickedColorIndex = id % 16;

//   return colors[pickedColorIndex];
// }
