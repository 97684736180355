import { useCallback, useState, useRef, useEffect } from 'react';
import analytics from '@analytics';
import copyToClipboard from 'copy-to-clipboard';
import Typography from './typography';

const useCopy = (
  str: string
): [boolean, () => void, (value: boolean) => void] => {
  const copyableString = useRef(str);
  const [copied, setCopied] = useState(false);

  const copyAction = useCallback(() => {
    const copiedString = copyToClipboard(copyableString.current.trim());
    setCopied(copiedString);
  }, [copyableString]);

  useEffect(() => {
    copyableString.current = str;
  }, [str]);

  return [copied, copyAction, setCopied];
};
interface Props {
  onCopyTrackingEvent?: string;
  url: string;
}

export const CopyLink: React.FC<Props> = ({
  onCopyTrackingEvent,
  url: str,
}) => {
  const [copied, copy, setCopied] = useCopy(str);

  const onCopy = useCallback(async () => {
    if (onCopyTrackingEvent) {
      analytics.track(onCopyTrackingEvent);
    }
    copy();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [onCopyTrackingEvent, setCopied, copy]);

  return (
    <div className="mt-1 flex h-10 items-center justify-between gap-3 rounded-lg border border-secondary-grey-light pl-2 pr-3">
      <Typography noWrap className="text-text-grey" variant="body-small">
        {str}
      </Typography>
      {copied ? (
        <Typography
          noWrap
          className="min-w-max text-chart-green-dark"
          variant="button"
        >
          Link copied
        </Typography>
      ) : (
        <button className="typography-button" type="button" onClick={onCopy}>
          Copy
        </button>
      )}
    </div>
  );
};

export default CopyLink;
