import { useEffect, useMemo } from 'react';
import analytics from '@analytics';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  TextInput,
  Typography,
  Checkbox,
  useIsUsernameAvailableLazyQuery,
  useSetUsernameMutation,
  useUnsubscribeContactGloballyMutation,
} from '../../../index';
import { maybeAddRelNofollow } from '../../../lib/utils';
import { useAlert } from '../../context/alert-context';
import { useSignUpOnPage } from '../../context/sign-up-on-page-context';

interface FormData {
  termsAndConditions: boolean;
  unsubscribe: boolean;
  username: string;
}

interface Props {
  companyName: string;
  isUK: boolean;
  marketListingKey: string;
}

const SignUpLiteUsername: React.ComponentType<Props> = ({
  companyName,
  isUK,
  marketListingKey,
}) => {
  useEffect(() => {
    analytics.track('signup_modal_username_viewed');
  }, []);

  const [isUsernameAvailable] = useIsUsernameAvailableLazyQuery();

  const { complete, user } = useSignUpOnPage();

  const defaultValues = useMemo(() => {
    return {
      // When signing up to an investor hub, UK investors must opt-in to receive emails from the company whilst AU investors can be opt-out
      unsubscribe: isUK ? true : false,

      username: user?.email
        ? `${user?.email
            .split('@')[0]
            .replace(/[^A-Za-z0-9 -]/g, '_')}_${Math.floor(
            Math.random() * 900 + 100
          )}`
        : '',
    };
  }, [user?.email, isUK]);

  const { formatAndShowError } = useAlert();

  const { control, formState, handleSubmit, register, reset, watch } =
    useForm<FormData>({
      defaultValues,
      mode: 'all',
      resolver: yupResolver(
        yup.object().shape({
          username: yup
            .string()
            .required('Required.')
            .matches(/^\w+$/, 'Only letters, numbers and underscores allowed.')
            .test(
              'isUsernameAvailable',
              'Oops! That username is already taken. Try another name.',
              async (value) => {
                try {
                  if (!value) return false;

                  const result = await isUsernameAvailable({
                    variables: {
                      username: value,
                    },
                  });

                  return !!result.data?.isUsernameAvailable;
                } catch (error) {
                  return false;
                }
              }
            )
            .min(6, 'Minimum 6 characters.\n'),
        })
      ),
    });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const [setUsername] = useSetUsernameMutation();

  const [setGlobalUnsubscribe] = useUnsubscribeContactGloballyMutation();

  const watchTermsAndConditions = watch('termsAndConditions');

  const onSubmit = handleSubmit(async ({ unsubscribe, username }) => {
    try {
      await setUsername({
        variables: {
          username,
        },
      });

      if (unsubscribe) {
        await setGlobalUnsubscribe();
      }

      analytics.track('signup_modal_username_set');
      await complete();
    } catch (error) {
      formatAndShowError(error);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-8">
        <Typography className="mb-4">
          Hi{' '}
          <span className="font-semibold">
            {user?.firstName ?? user?.email}
          </span>
          ,
        </Typography>
        <Typography className="mb-4">
          Welcome to the {companyName} investor community!
        </Typography>
        <Typography className="mb-8">
          Please set a username to continue creating your account.
        </Typography>
        <TextInput
          {...register('username')}
          autoComplete="username"
          className="mb-4 shadow-md"
          description="Your username is publicly visible and cannot be changed."
          error={!!formState.errors.username?.message}
          helperText={formState.errors.username?.message}
          id="username"
          label="Username"
        />
        {/* Subscribe opt-in (UK) / opt-out (AU)*/}
        <div className="mt-8 flex items-center gap-x-2 self-stretch">
          <Controller
            control={control}
            name="unsubscribe"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={!value}
                id="field-unsubscribe"
                onChange={() => onChange(!value)}
              />
            )}
          ></Controller>
          <label htmlFor="field-unsubscribe">
            <Typography variant="button">
              I want to receive news and updates from {companyName}, which are
              managed and sent through InvestorHub
            </Typography>
          </label>
        </div>
        <div className="mt-8 flex items-center gap-x-2 self-stretch">
          <Controller
            control={control}
            name="termsAndConditions"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                id="field-termsAndConditions"
                onChange={() => onChange(!value)}
              />
            )}
          ></Controller>
          <label htmlFor="field-termsAndConditions">
            <Typography>
              Yes, I am over 18 years old, I agree to the{' '}
              <Link
                className="text-inherit underline"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-terms-and-conditions'
                    : 'https://investorhub.com/au-microsite-terms-and-conditions'
                }
                rel={`${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                terms and conditions
              </Link>
              , and I have read the{' '}
              <Link
                className="text-inherit underline"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-privacy-policy'
                    : 'https://investorhub.com/au-microsite-privacy-policy'
                }
                rel={`${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                privacy policy
              </Link>{' '}
              *
            </Typography>
          </label>
        </div>
      </div>
      <div>
        <Button
          fullWidth
          disabled={formState.isSubmitting || !watchTermsAndConditions}
          testId="sign-up-btn"
          type="submit"
          onClick={() => analytics.track('signup_form_submitted')}
        >
          Save username
        </Button>
      </div>
    </form>
  );
};

export default SignUpLiteUsername;
