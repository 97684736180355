import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hasArrow?: boolean;
  hasBorder?: boolean;
  isAccent?: boolean;
  isPrimary?: boolean;
  label?: string;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  openInNewTab?: boolean;
  size?: 'sm' | 'md' | 'lg';
  startIcon?: React.ReactNode;
  url?: string;
}

export const HubButton: React.FC<ButtonProps> = ({
  children,
  className,
  disabled = false,
  hasArrow = false,
  hasBorder = false,
  isAccent = false,
  isPrimary = false,
  label,
  onClick,
  openInNewTab = false,
  size = 'md',
  startIcon,
  url,
}) => {
  const sizeClasses = {
    lg: 'px-5 py-3.5 text-md',
    md: 'px-4 py-3 text-sm',
    sm: 'px-3 py-2 text-xs',
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  const isLink = !!url;

  const buttonProps = {
    'aria-disabled': disabled,
    className: clsx(
      'group flex flex-row items-center gap-1.5 rounded-lg font-body shadow-none transition-shadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:hover:shadow-md',
      sizeClasses[size],
      isAccent
        ? 'bg-company-accent font-semibold text-company-accent-text focus-visible:outline-company-accent'
        : isPrimary
        ? 'bg-company-primary font-semibold text-company-primary-text focus-visible:outline-company-primary'
        : 'bg-gray-100 text-hubs-fixed-dark hover:bg-gray-200 focus-visible:outline-company-primary',
      hasBorder &&
        'border border-gray-300 hover:border-gray-400 hover:bg-gray-100 hover:text-hubs-primary',
      disabled && 'cursor-not-allowed opacity-50',
      className
    ),
    onClick: handleClick,
  };

  const buttonContent = (
    <>
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {label || children}
      {hasArrow && <span aria-hidden="true"> &rarr;</span>}
    </>
  );

  if (isLink) {
    return (
      <Link
        {...buttonProps}
        href={url}
        target={openInNewTab ? '_blank' : '_self'}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button {...buttonProps} disabled={disabled}>
      {buttonContent}
    </button>
  );
};
