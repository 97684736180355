import Image from 'next/legacy/image';
import { Typography } from '../../../../index';

interface Props {
  listingKey: string;
  logoUrl: string;
  marketKey: string;
  userName: string;
}

export const QuestionCompanyAuthor: React.ComponentType<Props> = ({
  listingKey,
  logoUrl,
  marketKey,
  userName,
}) => {
  return (
    <div>
      <div className="grid grid-cols-[48px_minmax(0,_1fr)] items-center gap-4 overflow-hidden">
        <Image
          alt={`${marketKey}:${listingKey} Logo`}
          className="min-w-max rounded-full"
          height={48}
          quality={100}
          src={logoUrl}
          width={48}
        />

        <div className="mb-1 flex flex-wrap items-center gap-2 overflow-hidden">
          <Typography
            className="typography-subtitle-1 break-all text-hubs-primary"
            variant="subtitle-1"
          >
            {userName}
          </Typography>

          <Typography
            className="rounded-full bg-company-primary px-2.5 text-company-primary-text"
            component="div"
            variant="fine-print"
          >
            {`${marketKey.toUpperCase()}:${listingKey.toUpperCase()}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
