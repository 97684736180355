import Link from 'next/link';
import { Badge, Typography } from '../../../..';
import { ShareholderBadge } from './shareholder-badge';

interface Props {
  isUser?: boolean;
  showShareholder: boolean;
  userName: string;
}

const QuestionInvestorAuthor: React.ComponentType<Props> = ({
  isUser,
  showShareholder,
  userName,
}) => {
  const url = `/profile/${userName}`;

  return (
    <div className="flex items-center gap-2">
      <Link href={url}>
        <Typography
          className="break-all text-hubs-primary hover:underline"
          variant="subtitle-1"
        >
          {userName}
        </Typography>
      </Link>
      {showShareholder ? <ShareholderBadge isUser={isUser} /> : null}
      {isUser && <Badge title="You" />}
    </div>
  );
};

export default QuestionInvestorAuthor;
