import React, { useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { NextButton } from './next-button';
import { PrevButton } from './prev-button';

export type UseDotButtonType = {
  onDotButtonClick: (index: number) => void;
  scrollSnaps: number[];
  selectedIndex: number;
};

export interface CarouselButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  isSelected?: boolean;
  onClick: () => void;
}

interface CarouselProps {
  numOfSlidesPerRow?: 1 | 2 | 3 | 4 | 5 | 6;
  options: EmblaOptionsType;
  showNavigationInCarouselMiddle?: boolean;
  showPrevNextButtonNavigation?: boolean;
  slides: React.ReactNode[];
}

export const Carousel: React.ComponentType<CarouselProps> = ({
  numOfSlidesPerRow = 1,
  options,
  showNavigationInCarouselMiddle = true,
  showPrevNextButtonNavigation = true,
  slides,
}) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  const getNumOfSlidesPerRowCss = () => {
    switch (numOfSlidesPerRow) {
      case 6:
        return 'sm:basis-1/6';
      case 5:
        return 'sm:basis-1/5';
      case 4:
        return 'sm:basis-1/4';
      case 3:
        return 'sm:basis-1/3';
      case 2:
        return 'sm:basis-1/2';

      default:
        return 'basis-full';
    }
  };

  return (
    <div className="relative space-y-12">
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex touch-pinch-zoom gap-8">
          {slides.map((slide, index) => (
            <div
              key={`${Math.random()}-carousel-${index}`}
              className={clsx(
                'min-w-0 shrink-0 grow-0 basis-full',
                getNumOfSlidesPerRowCss(),
                slides.length === index + 1 && 'sm:pr-8'
              )}
            >
              {slide}
            </div>
          ))}
        </div>
      </div>

      {showNavigationInCarouselMiddle && (
        <div className="absolute left-0 top-1/3 flex w-full items-center">
          <div className="flex w-full items-center justify-between gap-2">
            <PrevButton
              className="border-company-primary bg-company-primary text-company-primary-text opacity-50 hover:opacity-100"
              disabled={prevBtnDisabled}
              onClick={onPrevButtonClick}
            />
            <NextButton
              className="border-company-primary bg-company-primary text-company-primary-text opacity-50 hover:opacity-100"
              disabled={nextBtnDisabled}
              onClick={onNextButtonClick}
            />
          </div>
        </div>
      )}

      {showPrevNextButtonNavigation && (
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-end gap-2">
            <PrevButton
              className="border-company-primary bg-company-primary text-company-primary-text"
              disabled={prevBtnDisabled}
              onClick={onPrevButtonClick}
            />
            <NextButton
              className="border-company-primary bg-company-primary text-company-primary-text"
              disabled={nextBtnDisabled}
              onClick={onNextButtonClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
