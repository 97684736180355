import mixpanel from 'mixpanel-browser';

/**
 * A wrapper function for the mixpanel track method.
 *
 * @param event - The name of the event you're tracking.
 * @param properties - A dictionary of properties for the event. Optional.
 * @param options - Options for the track call. Optional.
 * @param callback - A callback function to be executed after the track call. Optional.
 */
function track(
  event: string,
  properties?: object,
  options?: object,
  callback?: () => void
): void {
  try {
    mixpanel.track(event, properties, options, callback);
  } catch (_error) {
    // Do nothing
  }
}

/**
 * A wrapper function for the mixpanel page method.
 *
 * @param category - The category of the page. Optional.
 * @param name - The name of the page. Optional.
 * @param properties - A dictionary of properties of the page. Optional.
 * @param options - A dictionary of options for the call. Optional.
 * @param callback - A callback function to be executed after the page call. Optional.
 */
function page(
  category?: string,
  name?: string,
  properties?: object,
  options?: object,
  callback?: () => void
): void {
  // If only one string argument is provided, it's assumed to be the name.
  if (category && !name && !properties && !options && !callback) {
    try {
      mixpanel.track(`Viewed ${category}`, {
        Locale: navigator.language,
        Mobile: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
        Platform: navigator.platform,
        event_original_name: category,
        name: category,
        path: document.location.pathname,
        referrer: document.referrer,
        search: document.location.search,
        title: document.title,
        url: document.location.href,
      });
    } catch (_error) {
      // Do nothing
    }
  } else {
    try {
      mixpanel.track(
        `Viewed ${name}`,
        {
          Locale: navigator.language,
          Mobile: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
          Platform: navigator.platform,
          event_original_name: name,
          name: name,
          path: document.location.pathname,
          referrer: document.referrer,
          search: document.location.search,
          title: name,
          url: document.location.href,
        },
        options,
        callback
      );
    } catch (_error) {
      // Do nothing
    }
  }
}

function setAnonymousId(anonymousId: string): void {
  try {
    mixpanel.identify(anonymousId);
  } catch (_error) {
    // Do nothing
  }
}

/**
 * A wrapper function for the mixpanel identify method.
 *
 * @param userId - The database ID for the user. Optional.
 * @param traits - A dictionary of traits you know about the user. Optional.
 * @param options - Options for the identify call. Optional.
 * @param callback - A callback function to be executed after the identify call. Optional.
 */
function identify(
  userId?: string,
  traits?: object,
  options?: object,
  callback?: () => void
): void {
  try {
    mixpanel.identify(userId);
    if (traits) {
      mixpanel.people.set(traits);
    }
  } catch (_error) {
    // Do nothing
  }
}

// Resets the identity. Used for logging out.
function reset(): void {
  try {
    mixpanel.reset();
  } catch (_error) {
    // Do nothing
  }
}

// Loads analytics (Mixpanel)
function loadIfConsented(app: 'athena' | 'hermes'): void {
  try {
    // Only enable Mixpanel session replay for production as we have quota limits.
    // Records 2% of all sessions for Hermes and 100% of all sessions for Athena.
    // https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#implementation--sampling
    const options =
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
        ? {
            record_block_selector:
              'input[type="password"], [data-private="true"]',
            record_collect_fonts: true,
            record_mask_text_selector: '',
            record_sessions_percent: app === 'athena' ? 100 : 2,
          }
        : undefined;

    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_KEY || '', options);
  } catch (_error) {
    // Do nothing
  }
}

const analytics = {
  identify,
  loadIfConsented,
  page,
  reset,
  setAnonymousId,
  track,
};

export default analytics;
