'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import DOMPurify from 'isomorphic-dompurify';
import { createSchema } from '../../block-note';

/*
  Renders BlockNote content as HTML.
  Use in conjunction with DynamicRenderBlockNote so you don't get SSR errors.
  https://www.blocknotejs.org/docs/advanced/nextjs

  Usage:
      import { DynamicRenderBlockNote } from '@leaf/ui';

      <DynamicRenderBlockNote content={mediaUpdate.contentDraft?.content || []} />
*/

interface RenderBlockNoteProps {
  className?: string;
  content: Block[];
  fullWidth?: boolean;
  plaintext?: boolean;
  showHeadings?: boolean;
}

export default function RenderBlockNote({
  className = 'blocknote prose font-body text-lg leading-normal text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary list-inside list-disc [&_li_p]:my-0',
  content,
  fullWidth = false,
  plaintext,
  showHeadings = true,
}: RenderBlockNoteProps) {
  const [contentHTML, setContentHTML] = useState<string>('');
  const editor = useCreateBlockNote({
    schema: createSchema(showHeadings),
  });

  // Update the HTML whenever the content changes
  useEffect(() => {
    const updateHTML = async () => {
      if (!content || content.length === 0) {
        setContentHTML('');
        return;
      }

      try {
        const html = await editor.blocksToHTMLLossy(content);

        if (html === '<p></p>') {
          setContentHTML('');
        } else {
          setContentHTML(html);
        }
      } catch (error) {
        setContentHTML('');
      }
    };

    updateHTML();
  }, [content, showHeadings, editor]);

  const blockContentToPlaintextHtml = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(contentHTML),
        }}
        className="text-hubs-secondary children:break-words"
      />
    );
  };

  const contentIsEmpty =
    Array.isArray(content) &&
    content.length === 1 &&
    Array.isArray(content[0].content) &&
    content[0].content.length === 0 &&
    content[0].children?.length === 0;

  if (contentIsEmpty || !contentHTML) {
    return null;
  }

  if (plaintext) {
    return blockContentToPlaintextHtml();
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentHTML) }}
      className={`${className}${fullWidth ? ' max-w-none' : ''}`}
    />
  );
}
