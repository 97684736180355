'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useMemo, useState } from 'react';
import { type Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import { CameraIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import { getCldImageUrl } from 'next-cloudinary';
import Typography, { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { HubButtonData, ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { HubButton } from '../ui/hub-button';
import { emitBlockClickEvent } from '../utils';

export interface TextBlockProps {
  blockId: string;
  content: {
    description?: Block[];
    heading?: string;
    image?: ImageMeta | null;
    imageAltText?: string;
    imageCaption?: string;
    primaryButton?: HubButtonData;
    variant: 'standard_text' | 'text_with_left_image' | 'text_with_right_image';
  };
  hubBuilderUrl?: string;
}

// --------------------- TEXT BLOCK VARIANT RENDERER ---------------------
export const TextBlock: React.ComponentType<TextBlockProps> = ({
  blockId,
  content,
  hubBuilderUrl,
}) => {
  if (
    content.variant === 'text_with_left_image' ||
    content.variant === 'text_with_right_image'
  ) {
    return (
      <TextBlockWithImage
        blockId={blockId}
        content={content}
        hubBuilderUrl={hubBuilderUrl}
      />
    );
  }
  return (
    <StandardTextBlock
      blockId={blockId}
      content={content}
      hubBuilderUrl={hubBuilderUrl}
    />
  );
};

// --------------------- VARIANTS ---------------------
// STANDARD TEXT BLOCK
export const StandardTextBlock: React.ComponentType<TextBlockProps> = ({
  blockId,
  content,
  hubBuilderUrl,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  const getPrimaryButtonUrl = (url: string) => {
    if (url === '/') {
      return hubBuilderUrl ? hubBuilderUrl + '/home' : '/';
    }

    if (url.startsWith('/')) {
      return (hubBuilderUrl ?? '') + url;
    }

    return url;
  };

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content.description) {
        const html = await editor.blocksToFullHTML(content.description);
        if (html === '<p></p>') {
          setDescriptionHTML('');
        } else {
          setDescriptionHTML(html);
        }
      }
    };

    convertDescriptionToHTML();
  }, [editor, content.description]);

  return (
    <div
      className={clsx(BLOCK_WIDTH_CLASS, 'flex flex-col gap-3.5')}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.heading !== '' && <BlockHeading>{content.heading}</BlockHeading>}
      {descriptionHTML && (
        <div className="bn-container">
          <div
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
            className="bn-default-styles prose max-w-sm overflow-auto font-body text-lg leading-normal text-hubs-primary marker:text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary sm:max-w-none sm:overflow-auto [&_li_p]:my-0"
          />
        </div>
      )}
      {content.primaryButton?.label && (
        <div className="z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row">
          <HubButton
            isPrimary
            className="w-full justify-center md:w-auto"
            label={content.primaryButton.label}
            openInNewTab={
              !getPrimaryButtonUrl(content.primaryButton.url ?? '/').startsWith(
                '/'
              )
            }
            size="lg"
            url={getPrimaryButtonUrl(content.primaryButton.url ?? '/')}
          />
        </div>
      )}
    </div>
  );
};

// TEXT BLOCK WITH LEFT || RIGHT IMAGE
export const TextBlockWithImage: React.ComponentType<TextBlockProps> = ({
  blockId,
  content,
  hubBuilderUrl,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const editor = useCreateBlockNote();

  function isVariantLeft(variant: string) {
    return variant === 'text_with_left_image';
  }
  const isLeft = isVariantLeft(content.variant);

  const defaultUrl = hubBuilderUrl ? '/home' : '/';

  const getPrimaryButtonUrl = (url: string) => {
    if (url === '/') {
      return hubBuilderUrl ? hubBuilderUrl + '/home' : '/';
    }

    if (url.startsWith('/')) {
      return (hubBuilderUrl ?? '') + url;
    }

    return url;
  };

  useEffect(() => {
    if (!content.image) return;

    const { cloudinaryPublicId, cloudinaryUrl, height, width } = content.image;
    if (height && width) {
      setDimensions({ height, width });
    } else if (cloudinaryUrl) {
      const img = new Image();
      img.src = cloudinaryUrl as string;
      img.onload = () => {
        setDimensions({ height: img.height, width: img.width });
      };
    } else if (cloudinaryPublicId) {
      const img = new Image();
      img.src = getCldImageUrl({
        height: img.height,
        src: cloudinaryPublicId,
        width: img.width,
      });
    }
  }, [content.image]);

  const aspectRatioClass =
    dimensions.height && dimensions.width
      ? `aspect-[${dimensions.width}/${dimensions.height}]`
      : '';

  const objectFitClass =
    dimensions.height > 720 ? 'object-contain' : 'object-cover';

  const maybeAddRoundedCornersToImageUrl = useMemo(() => {
    if (!content.image || !content.image.cloudinaryUrl) return '';
    if (content.image.cloudinaryUrl) {
      const url = content.image.cloudinaryUrl;

      if (objectFitClass !== 'object-contain') {
        return url;
      }

      const searchPattern = 'upload/';
      const index = url.indexOf(searchPattern);

      if (index === -1) {
        return url;
      }

      const prefix = url.slice(0, index + searchPattern.length);
      const suffix = url.slice(index + searchPattern.length);

      return `${prefix}r_16/${suffix}`;
    }
    if (content.image.cloudinaryPublicId) {
      return getCldImageUrl({
        radius: '16',
        src: content.image.cloudinaryPublicId,
      });
    }
    return '';
  }, [content.image, objectFitClass]);

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content.description) {
        const html = await editor.blocksToFullHTML(content.description);
        if (html === '<p></p>') {
          setDescriptionHTML('');
        } else {
          setDescriptionHTML(html);
        }
      }
    };

    convertDescriptionToHTML();
  }, [editor, content.description]);

  return (
    <div
      className={clsx(
        BLOCK_WIDTH_CLASS,
        'flex flex-col items-start justify-between gap-8 md:grid md:grid-cols-2 md:gap-20'
      )}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className={clsx('space-y-4', isLeft ? 'order-1' : 'order-3')}>
        {!!dimensions.width && !!dimensions.height && !!content.image && (
          <div
            className={clsx(
              'relative flex w-full justify-center rounded-lg',
              aspectRatioClass
            )}
          >
            <CloudinaryImage
              alt={
                content.imageAltText ||
                content.image.originalFilename ||
                'Untitled image'
              }
              className={clsx(`max-h-[720px] rounded-lg`, objectFitClass)}
              height={dimensions.height}
              sizes="100vw"
              src={maybeAddRoundedCornersToImageUrl}
              width={dimensions.width}
            />
          </div>
        )}

        {content.imageCaption && (
          <div className="flex items-start gap-2">
            <div className="mt-1 h-5 w-5">
              <CameraIcon className="h-5 w-5 text-hubs-secondary" />
            </div>
            <Typography
              className="font-body text-lg leading-normal text-hubs-secondary"
              component="div"
              variant="paragraph"
            >
              {content.imageCaption}
            </Typography>
          </div>
        )}
      </div>
      {/* AS: my-auto here instead of items-center, as we want it centered when not a lot of content next to image, but top aligned when lots of content next to image */}
      <div className="order-2 my-auto flex flex-col gap-3.5">
        {content.heading !== '' && (
          <BlockHeading>{content.heading}</BlockHeading>
        )}
        {descriptionHTML && (
          <div className="bn-container">
            <div
              dangerouslySetInnerHTML={{ __html: descriptionHTML }}
              className="bn-default-styles prose max-w-sm overflow-auto font-body text-lg leading-normal text-hubs-primary marker:text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary sm:max-w-none sm:overflow-auto [&_li_p]:my-0"
            />
          </div>
        )}
        {content.primaryButton?.label && (
          <div className="z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row">
            <HubButton
              isPrimary
              className="w-full justify-center md:w-auto"
              label={content.primaryButton.label}
              openInNewTab={
                !getPrimaryButtonUrl(
                  content.primaryButton.url ?? defaultUrl
                ).startsWith('/')
              }
              size="lg"
              url={getPrimaryButtonUrl(content.primaryButton.url ?? defaultUrl)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
