import React, { useRef, useState } from 'react';
import analytics from '@analytics';
import {
  Bars3BottomLeftIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  NewspaperIcon,
  XMarkIcon,
} from '@heroicons/react-v2/24/outline';
import { CalendarIcon, StarIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'usehooks-ts';
import {
  Button,
  Typography,
  QuickFilterType,
  QuickFilterWithLabel,
} from '../../../index';
import { QuickFiltersMobile } from './quick-filters-mobile';
type SetAppliedFiltersType = React.Dispatch<
  React.SetStateAction<QuickFilterType[]>
>;

const FilterDatePicker: React.FC<{
  appliedFilters: QuickFilterType[];
  setAppliedFilters: SetAppliedFiltersType;
}> = ({ appliedFilters, setAppliedFilters }) => {
  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  type MaybeDate = string | boolean | Date | undefined;
  const [startDate, setStartDate] = useState<MaybeDate>('');
  const [endDate, setEndDate] = useState<MaybeDate>('');

  const [selectedStartDate, setSelectedStartDate] = useState<MaybeDate>('');
  const [selectedEndDate, setSelectedEndDate] = useState<MaybeDate>('');

  const selectedText = () => {
    const startDisplay = startDate
      ? dayjs(startDate as string).format('DD/MM/YY')
      : '';
    const endDisplay = endDate
      ? dayjs(endDate as string).format('DD/MM/YY')
      : '';
    if (startDate || endDate) {
      return `${startDisplay} - ${endDisplay}`;
    }
    return 'Date range';
  };

  const today = dayjs().format('YYYY-MM-DD');

  React.useEffect(() => {
    if (!appliedFilters) return;
    const start = appliedFilters.find((sf) => sf.key === 'startDate')?.value;
    const end = appliedFilters.find((sf) => sf.key === 'endDate')?.value;

    setStartDate(start);
    setEndDate(end);
  }, [appliedFilters]);

  React.useEffect(() => {
    setSelectedStartDate(startDate ?? '');
  }, [startDate]);

  React.useEffect(() => {
    setSelectedEndDate(endDate ?? '');
  }, [endDate]);

  const clearDates = () => {
    setAppliedFilters([
      ...appliedFilters.filter(
        (sf) => !['startDate', 'endDate'].includes(sf.key)
      ),
    ]);
    setRangePickerOpen(false);
  };

  const ref = useRef(null);

  const handleClickOutside = () => {
    setRangePickerOpen(false);
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <div ref={ref} className="relative z-10 w-full md:w-auto">
      <Button
        className="w-full md:w-auto"
        color={
          appliedFilters.some((f) => f.key === 'startDate')
            ? 'company'
            : undefined
        }
        size="md"
        startIcon={<CalendarIcon className="h-5 w-5" />}
        variant={
          appliedFilters.some((f) => f.key === 'startDate')
            ? 'primary'
            : 'secondary'
        }
        onClick={() => setRangePickerOpen((prev) => !prev)}
      >
        {selectedText()}
      </Button>

      <div
        className={clsx(
          'absolute z-20 mt-5 w-full rounded-lg border bg-hubs-background px-5 py-4 shadow-lg md:w-auto md:min-w-[400px]',
          rangePickerOpen ? 'block' : 'hidden'
        )}
      >
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full items-center justify-between">
            <Typography className="text-hubs-secondary">Date range</Typography>
            <XMarkIcon
              className="h-4 w-4 cursor-pointer"
              onClick={() => setRangePickerOpen(false)}
            />
          </div>
          <div className="flex w-full flex-col justify-between gap-4 md:flex-row md:items-center">
            <input
              className="input flex-1 shrink-0 rounded-md border border-secondary-grey-light"
              max={(selectedEndDate ? selectedEndDate : today) as string}
              min="2014-01-01"
              name="start"
              type="date"
              value={selectedStartDate as string}
              onChange={(e) => setSelectedStartDate(e.target.value)}
            />
            <input
              className="input flex-1 shrink-0 rounded-md border border-secondary-grey-light"
              max={today}
              min={
                (selectedStartDate ? selectedStartDate : '2014-01-01') as string
              }
              name="end"
              type="date"
              value={selectedEndDate as string}
              onChange={(e) => setSelectedEndDate(e.target.value)}
            />
          </div>
          <div className="w-full">
            <span
              className="cursor-pointer text-hubs-secondary underline"
              onClick={() => clearDates()}
            >
              Clear dates
            </span>
          </div>
          <div className="w-full">
            <Button
              fullWidth
              disabled={!(selectedStartDate && selectedEndDate)}
              size="md"
              onClick={() => {
                analytics.track('hermes_sort_or_filter', {
                  feature: 'announcements',
                  filter: 'date range',
                  hubs_version: '2',
                });
                setAppliedFilters([
                  ...appliedFilters.filter(
                    (sf) => !['startDate', 'endDate'].includes(sf.key)
                  ),
                  { key: 'startDate', value: selectedStartDate as string },
                  { key: 'endDate', value: selectedEndDate as string },
                ]);
                setRangePickerOpen(false);
              }}
            >
              Apply date range
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const QuickFilters: React.FC<{
  appliedFilters: QuickFilterType[];
  isFeatured: boolean;
  isUK: boolean;
  setAppliedFilters: SetAppliedFiltersType;
  tags?: string[];
}> = ({
  appliedFilters,
  isFeatured = false,
  isUK,
  setAppliedFilters,
  tags,
}) => {
  const FilterButton: React.FC<{
    Icon: React.ForwardRefExoticComponent<
      Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
        title?: string | undefined;
        titleId?: string | undefined;
      } & React.RefAttributes<SVGSVGElement>
    >;
    filter: QuickFilterWithLabel;
  }> = ({ Icon, filter }) => {
    const applied =
      filter.key === 'news_publishers'
        ? appliedFilters.some(
            (f) =>
              f.key === 'news_publishers' &&
              (f.value as string).includes(filter.label)
          )
        : appliedFilters.some((f) => f.key === filter.key);
    return (
      <Button
        color={applied ? 'company' : undefined}
        size="md"
        startIcon={<Icon className="h-5 w-5 shrink-0" />}
        variant={applied ? 'primary' : 'secondary'}
        onClick={() => {
          analytics.track('hermes_sort_or_filter', {
            feature: 'announcements',
            filter: filter.label.toLowerCase(),
            hubs_version: '2',
          });
          if (mobileFiltersOpen) setMobileFiltersOpen(false);
          setAppliedFilters([
            ...appliedFilters.filter((sf) => sf.key !== filter.key),
            ...(applied
              ? []
              : [
                  {
                    key: filter.key,
                    value: filter.value,
                  },
                ]),
          ]);
        }}
      >
        {filter?.label ?? ''}
      </Button>
    );
  };
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const filtersContent = () => (
    <>
      <div className="flex w-full flex-col gap-3 md:w-auto md:flex-row">
        {isFeatured && (!tags || !tags.length) ? (
          <FilterButton
            Icon={StarIcon}
            filter={{
              key: 'featured',
              label: 'Featured',
              value: 'true',
            }}
          />
        ) : null}
        {!isUK ? (
          !tags || !tags.length ? (
            <>
              <FilterButton
                Icon={CurrencyDollarIcon}
                filter={{
                  key: 'price_sensitive',
                  label: 'Price sensitive',
                  value: 'true',
                }}
              />
              <FilterButton
                Icon={DocumentTextIcon}
                filter={{
                  key: 'subtypes',
                  label: 'Periodic reports',
                  // Here we list every subtype EXCEPT 03026, because this represents the category "Company presentation", which is not a periodic report. See https://investor-hub.slack.com/archives/C903ALC05/p1738559034781709
                  value:
                    '03001, 03002, 03003, 03004, 03005, 03006, 03007, 03008, 03009, 03010, 03011, 03012, 03013, 03014, 03015, 03016, 03017, 03018, 03019, 03020, 03021, 03022, 03023, 03024, 03025, 03027, 03028, 03029',
                }}
              />
            </>
          ) : null
        ) : (
          <>
            <FilterButton
              Icon={NewspaperIcon}
              filter={{
                key: 'news_publishers',
                label: 'RNS',
                value: 'RNS',
              }}
            />
            <FilterButton
              Icon={NewspaperIcon}
              filter={{
                key: 'news_publishers',
                label: 'Reach',
                value: 'Reach',
              }}
            />
          </>
        )}

        <FilterButton
          Icon={Bars3BottomLeftIcon}
          filter={{
            key: 'summary',
            label: 'With summary',
            value: 'true',
          }}
        />
      </div>
      <FilterDatePicker
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
      />
    </>
  );
  return (
    <div className="flex w-full items-center justify-start md:w-auto">
      <div className="hidden w-auto grow gap-3 md:flex">{filtersContent()}</div>
      <QuickFiltersMobile
        open={mobileFiltersOpen}
        setOpen={setMobileFiltersOpen}
      >
        {filtersContent()}
      </QuickFiltersMobile>
    </div>
  );
};
