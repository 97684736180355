import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import SignInLite from '../auth/sign-in-lite/sign-in';
import SignUpLiteComplete from '../auth/sign-up-lite/complete';
import SignUpLiteDetails from '../auth/sign-up-lite/details';
import SignUpLiteEmail from '../auth/sign-up-lite/email';
import SignUpLiteUsername from '../auth/sign-up-lite/username';
import SignUpLiteVerify from '../auth/sign-up-lite/verify';
import {
  Typography,
  useReactToMediaCommentMutation,
  useReactToMediaMutation,
  useUpsertFollowMutation,
} from '../index';
import { useAlert } from './alert-context';

export type AttemptedAction =
  | 'like'
  | 'follow'
  | 'like-question'
  | 'follow-question';

interface SignUpOnPageContext {
  complete: () => void | Promise<void>;
  setStep: Dispatch<SetStateAction<Step>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  step: Step;
  user: User | null;
}

const SignUpOnPageContext = createContext<SignUpOnPageContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  complete() {},
  setStep: () => 'email',
  setUser: () => null,
  step: 'email',
  user: null,
});

type Step =
  | 'email'
  | 'details'
  | 'username'
  | 'verify'
  | 'sign-in'
  | 'complete';

type User = {
  email: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  unsubscribe?: boolean;
  username?: string;
};

interface Props {
  attemptedAction?: AttemptedAction;
  companyId: string;
  companyName: string;
  isUK: boolean;
  listingKey: string;
  login?: (
    companyProfileId: string,
    email: string,
    password: string,
    rememberMe?: boolean,
    shareholderHashId?: string
  ) => Promise<void>;
  logoUrl: string;
  marketKey: string;
  marketListingKey: string;
  mediaCommentId?: string;
  mediaId?: string;
}

export const SignUpOnPageProvider: React.ComponentType<Props> = ({
  attemptedAction,
  companyId,
  companyName,
  isUK,
  listingKey,
  login,
  logoUrl,
  marketKey,
  marketListingKey,
  mediaCommentId,
  mediaId,
}) => {
  const [step, setStep] = useState<Step>('email');

  const [user, setUser] = useState<User | null>(null);

  const { formatAndShowError } = useAlert();

  const [reactToMedia] = useReactToMediaMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['MediaLike', 'CurrentInvestorUser'],
  });

  const [reactToMediaComment] = useReactToMediaCommentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'MediaComments',
      'InvestorMediaComments',
      'CurrentInvestorUser',
    ],
  });

  const [upsertFollow] = useUpsertFollowMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'MediaFollow',
      'MediaComments',
      'InvestorMediaComments',
      'CurrentInvestorUser',
    ],
  });

  const likeMedia = () => {
    if (mediaId) {
      reactToMedia({
        variables: {
          like: true,
          mediaId,
        },
      })
        .then(() => setStep('complete'))
        .catch(formatAndShowError);
    }
  };

  const likeMediaComment = () => {
    if (mediaCommentId) {
      reactToMediaComment({
        variables: {
          like: true,
          mediaCommentId,
        },
      })
        .then(() => setStep('complete'))
        .catch(formatAndShowError);
    }
  };

  const follow = () => {
    upsertFollow({
      variables: {
        follow: {
          followingMediaCommentId: mediaCommentId,
          followingMediaId: mediaId,
          invalidated: false,
        },
      },
    });
  };

  const complete = () => {
    if (attemptedAction === 'like') {
      // Submit the like
      return likeMedia();
    }
    if (attemptedAction === 'like-question') {
      // Submit the like
      return likeMediaComment();
    }
    if (attemptedAction === 'follow' || attemptedAction === 'follow-question') {
      // Submit the follow
      return follow();
    }
    if (!attemptedAction) {
      return setStep('complete');
    }
  };

  const renderContent = useMemo(() => {
    if (step === 'email')
      return (
        <SignUpLiteEmail
          companyName={companyName}
          isUK={isUK}
          listingKey={listingKey}
          marketListingKey={marketListingKey}
        />
      );
    if (step === 'details') return <SignUpLiteDetails />;
    if (step === 'username')
      return (
        <SignUpLiteUsername
          companyName={companyName}
          isUK={isUK}
          marketListingKey={marketListingKey}
        />
      );
    if (step === 'verify') return <SignUpLiteVerify />;
    if (step === 'sign-in' && login)
      return <SignInLite companyId={companyId} login={login} />;
    if (step === 'complete')
      return <SignUpLiteComplete attemptedAction={attemptedAction} />;
    return null;
  }, [
    attemptedAction,
    companyId,
    companyName,
    isUK,
    listingKey,
    login,
    marketListingKey,
    step,
  ]);

  const title = useMemo(() => {
    if (step === 'email') return 'Welcome!';
    if (step === 'details') return 'Create an account';
    if (step === 'username') return 'Set up your account';
    if (step === 'verify') return 'Verify your email';
    if (step === 'sign-in') return 'Sign in';
    return null;
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 'email')
      return 'Sign up to start asking us questions and interacting with our investor community';

    return null;
  }, [step]);

  const stepText = useMemo(() => {
    if (step === 'details') return 'Step 1 of 3';
    if (step === 'verify') return 'Step 2 of 3';
    return null;
  }, [step]);

  return (
    <SignUpOnPageContext.Provider
      value={{ complete, setStep, setUser, step, user }}
    >
      <div className="!mt-0 sm:rounded-lg">
        <div className={clsx(title ? 'mb-8 space-y-4' : '')}>
          {title ? (
            <>
              <Image
                alt={listingKey}
                height={64}
                quality={100}
                src={logoUrl}
                width={64}
              />
              <div>
                {stepText ? (
                  <Typography
                    className="text-hubs-secondary"
                    variant="subtitle-2"
                  >
                    {stepText}
                  </Typography>
                ) : null}
                <Typography className="text-hubs-primary" variant="heading-4">
                  {title}
                </Typography>
              </div>
            </>
          ) : null}
          {subtitle ? (
            <Typography className="text-hubs-secondary" variant="body-regular">
              {subtitle}
            </Typography>
          ) : null}
        </div>
        {renderContent}
      </div>
    </SignUpOnPageContext.Provider>
  );
};

export function useSignUpOnPage() {
  return useContext(SignUpOnPageContext);
}
