import { VideoCameraIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { Typography } from '../../typography';

interface Props {
  isLive: boolean;
  webinar: {
    attendanceNeedsLogin: boolean;
    id: string;
    slug: string;
    title: string;
  };
}

export const WebinarBanner = ({ isLive, webinar }: Props) => {
  const router = useRouter();
  const beforeWebinarText = webinar.attendanceNeedsLogin
    ? 'Register for our next webinar!'
    : 'View our upcoming webinar!';
  return (
    <div className="flex flex-1 items-center justify-center gap-3 bg-company-primary px-4 py-3 text-company-primary-text">
      {isLive ? (
        <span className="relative flex h-[14px] w-[14px] items-center justify-center">
          <span
            className="absolute inline-flex h-full w-full animate-ping rounded-full bg-white/[0.5]"
            style={{ animationDuration: '1.5s' }}
          />
          <span className="relative inline-flex h-[8px] w-[8px] rounded-full bg-white" />
        </span>
      ) : (
        <VideoCameraIcon className="h-6 w-6" />
      )}
      <Typography variant="subtitle-2">
        {isLive ? 'Our webinar is now live!' : beforeWebinarText}
      </Typography>
      <span
        className="cursor-pointer underline"
        onClick={() => {
          router.push(`/webinars/${webinar.slug}`);
        }}
      >
        <Typography className="font-bold">
          {isLive ? 'Join →' : 'Go →'}
        </Typography>
      </span>
    </div>
  );
};
