'use client';

import React, { useRef } from 'react';
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react-v2/24/outline';
import { VideoCameraIcon } from '@heroicons/react-v2/24/solid';
import { Tooltip } from 'react-tooltip';
import { Typography } from '../../../index';
import { useFullscreen } from './webinar-util';

interface LiveWebinarProps {
  buildUrl: string;
  duration: string;
  isLive: boolean;
  isSpeakerLink: boolean;
  shouldShowStatus: boolean;
  title: string;
}

export const LiveWebinar: React.FC<LiveWebinarProps> = ({
  buildUrl,
  duration,
  isLive,
  isSpeakerLink,
  shouldShowStatus,
  title,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(videoContainerRef);
  const scheduledTitle = () => {
    return (
      <>
        <Tooltip
          anchorSelect="#scheduled-title"
          content="Start and manage the webinar in the studio."
        />
        <div
          className="flex animate-flash items-center space-x-2"
          id="scheduled-title"
        >
          <VideoCameraIcon className="size-4 text-white" />
          <Typography className="text-white" variant="body-small">
            Ready to go live & record
          </Typography>
        </div>
      </>
    );
  };

  const liveTitle = () => {
    return (
      <div className="flex items-center space-x-2">
        <span className="relative flex h-[16px] w-[16px] items-center justify-center">
          <span
            className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-600/[0.5]"
            style={{ animationDuration: '1.5s' }}
          />
          <span className="relative inline-flex h-[8px] w-[8px] rounded-full bg-red-600" />
        </span>
        <Typography className="text-white" variant="body-small">
          {isSpeakerLink
            ? 'Live & recording: ' + duration
            : 'Live: ' + duration}
        </Typography>
      </div>
    );
  };

  return (
    <div
      ref={videoContainerRef}
      className="relative flex h-[650px] w-full flex-col"
    >
      <div className="flex h-10 w-full items-center justify-between bg-[rgb(5,7,14)] px-4">
        {shouldShowStatus && (isLive ? liveTitle() : scheduledTitle())}

        <button
          aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
          className="justify-end rounded-full bg-black/50 p-2 text-white hover:bg-black/70 sm:block"
          onClick={toggleFullscreen}
        >
          {isFullscreen ? (
            <ArrowsPointingInIcon className="h-6 w-6" />
          ) : (
            <ArrowsPointingOutIcon className="h-6 w-6" />
          )}
        </button>
      </div>
      <iframe
        allow="camera *;microphone *;display-capture *"
        className="flex-1 border-0"
        src={buildUrl}
        title={title || 'Webinar'}
      ></iframe>
    </div>
  );
};
