import React, { useEffect, useMemo, useState } from 'react';
import { PhotographIcon } from '@heroicons/react/outline';
import { CameraIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import { getCldImageUrl } from 'next-cloudinary';
import Typography from '../../typography';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta, ImageBase } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { emitBlockClickEvent } from '../utils';

export interface ImageBlockProps {
  blockId?: string;
  content: {
    galleryColumns?: number;
    galleryImages?: ImageBase[];
    heading?: string;
    singleImage?: ImageBase;
    variant: 'single_image' | 'gallery';
  };
  style?: React.CSSProperties;
}

export const ImageBlock: React.ComponentType<ImageBlockProps> = ({
  blockId,
  content,
  style,
}) => {
  return content.variant === 'gallery' ? (
    <GalleryBlock blockId={blockId} content={content} style={style} />
  ) : (
    <SingleImageBlock blockId={blockId} content={content} style={style} />
  );
};

const SingleImageBlock: React.ComponentType<ImageBlockProps> = ({
  blockId,
  content,
  style,
}) => {
  const singleImage = content.singleImage;
  const {
    aspectRatioClass,
    dimensions,
    isLoading,
    maybeAddRoundedCornersToImageUrl,
    objectFitClass,
  } = useImageProcessing(singleImage?.image ?? null);

  if (!singleImage) return null;

  return (
    <div
      className={clsx(BLOCK_WIDTH_CLASS, 'relative space-y-4')}
      style={style}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {isLoading ? (
        <div className="flex w-full justify-center">
          <div
            className="w-full rounded-lg bg-gray-100"
            style={{ height: '200px' }}
          />
        </div>
      ) : !!dimensions.width && !!dimensions.height && !!singleImage.image ? (
        <div
          className={clsx(
            'relative flex w-full justify-center rounded-lg',
            aspectRatioClass
          )}
        >
          <CloudinaryImage
            alt={
              singleImage.alt ||
              singleImage.image.originalFilename ||
              'Untitled image'
            }
            className={clsx(`max-h-[1080px] rounded-lg`, objectFitClass)}
            height={dimensions.height}
            sizes="100vw"
            src={maybeAddRoundedCornersToImageUrl}
            width={dimensions.width}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500"
            style={{ height: 200, width: 200 }}
          >
            <PhotographIcon className="h-6 w-6" />
            <span className="text-sm">No image</span>
          </div>
        </div>
      )}

      {singleImage.caption && (
        <div className="flex items-start gap-2">
          <div className="mt-1 h-5 w-5">
            <CameraIcon className="h-5 w-5 text-hubs-secondary" />
          </div>
          <Typography
            className="font-body text-lg leading-normal text-hubs-secondary"
            component="div"
            variant="paragraph"
          >
            {singleImage.caption}
          </Typography>
        </div>
      )}
    </div>
  );
};

const GalleryBlock: React.ComponentType<ImageBlockProps> = ({
  blockId,
  content,
  style,
}) => {
  if (!content.galleryImages || content.galleryImages.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(BLOCK_WIDTH_CLASS, 'relative space-y-4')}
      style={style}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.heading && content.heading !== '' && (
        <div className="my-auto flex flex-col gap-4">
          <BlockHeading>{content.heading}</BlockHeading>
        </div>
      )}

      <div
        className={clsx('columns-2 gap-2 space-y-2', {
          'md:columns-2': content.galleryColumns === 2,
          'md:columns-3': content.galleryColumns === 3,
          'md:columns-4': content.galleryColumns === 4,
        })}
      >
        {content.galleryImages.map((imageBase, index) => (
          <GalleryImage
            key={String(index.toString() + blockId)}
            imageBase={imageBase}
          />
        ))}
      </div>
    </div>
  );
};

const GalleryImage: React.ComponentType<{ imageBase: ImageBase }> = ({
  imageBase,
}) => {
  const {
    aspectRatioClass,
    dimensions,
    isLoading,
    maybeAddRoundedCornersToImageUrl,
    objectFitClass,
  } = useImageProcessing(imageBase.image);

  return (
    <div>
      {isLoading ? (
        <div className="flex w-full justify-center">
          <div
            className="w-full rounded-lg bg-gray-100"
            style={{ height: '200px' }}
          />
        </div>
      ) : !!dimensions.width && !!dimensions.height && !!imageBase.image ? (
        <div
          className={clsx(
            'relative flex w-full justify-center rounded-lg',
            aspectRatioClass
          )}
        >
          <CloudinaryImage
            alt={
              imageBase.alt ||
              imageBase.image.originalFilename ||
              'Untitled image'
            }
            className={clsx(`max-h-[1080px] rounded-lg`, objectFitClass)}
            height={dimensions.height}
            sizes="100vw"
            src={maybeAddRoundedCornersToImageUrl}
            width={dimensions.width}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500"
            style={{ height: 200, width: 200 }}
          >
            <PhotographIcon className="h-6 w-6" />
            <span className="text-sm">No image</span>
          </div>
        </div>
      )}
    </div>
  );
};

const useImageProcessing = (image: ImageMeta | null) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!image) {
      setIsLoading(false);
      return;
    }

    const { cloudinaryPublicId, cloudinaryUrl, height, width } = image;
    if (height && width) {
      setDimensions({ height, width });
      setIsLoading(false);
    } else if (cloudinaryUrl) {
      const img = new Image();
      img.src = cloudinaryUrl as string;
      img.onload = () => {
        setDimensions({ height: img.height, width: img.width });
        setIsLoading(false);
      };
    } else if (cloudinaryPublicId) {
      const img = new Image();
      img.src = getCldImageUrl({
        height: img.height,
        src: cloudinaryPublicId,
        width: img.width,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [image]);

  const aspectRatioClass =
    dimensions.height && dimensions.width
      ? `aspect-[${dimensions.width}/${dimensions.height}]`
      : '';

  const objectFitClass =
    dimensions.height > 1080 ? 'object-contain' : 'object-cover';

  const maybeAddRoundedCornersToImageUrl = useMemo(() => {
    if (!image?.cloudinaryUrl) return '';

    if (image.cloudinaryUrl) {
      if (objectFitClass !== 'object-contain') return image.cloudinaryUrl;

      const searchPattern = 'upload/';
      const index = image.cloudinaryUrl.indexOf(searchPattern);
      if (index === -1) return image.cloudinaryUrl;

      const prefix = image.cloudinaryUrl.slice(0, index + searchPattern.length);
      const suffix = image.cloudinaryUrl.slice(index + searchPattern.length);
      return `${prefix}r_16/${suffix}`;
    }

    if (image.cloudinaryPublicId) {
      return getCldImageUrl({
        radius: '16',
        src: image.cloudinaryPublicId,
      });
    }

    return '';
  }, [image, objectFitClass]);

  return {
    aspectRatioClass,
    dimensions,
    isLoading,
    maybeAddRoundedCornersToImageUrl,
    objectFitClass,
  };
};
