'use client';
import {
  Block,
  BlockNoteEditor,
  BlockNoteSchema,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  defaultStyleSpecs,
  filterSuggestionItems,
} from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import {
  SuggestionMenuController,
  useCreateBlockNote,
  DefaultReactSuggestionItem,
  getDefaultReactSlashMenuItems,
} from '@blocknote/react';
import '@blocknote/mantine/style.css';

export const createSchema = (showHeadings = true) => {
  const customBlockSpecs = {
    ...(showHeadings
      ? defaultBlockSpecs
      : Object.fromEntries(
          Object.entries(defaultBlockSpecs).filter(([key]) => key !== 'heading')
        )),
  };

  const schema = {
    blockSpecs: customBlockSpecs,
    inlineContentSpecs: defaultInlineContentSpecs,
    styleSpecs: defaultStyleSpecs,
  };

  return BlockNoteSchema.create(schema);
};

const getCustomSlashMenuItems = (
  editor: BlockNoteEditor
): DefaultReactSuggestionItem[] => {
  const defaultItems = getDefaultReactSlashMenuItems(editor);

  // Group items by their group
  const groupedItems: Record<string, DefaultReactSuggestionItem[]> = {};

  defaultItems.forEach((item) => {
    const group = item.group || 'Other';
    if (!groupedItems[group]) {
      groupedItems[group] = [];
    }
    groupedItems[group].push(item);
  });

  // Flatten the groups back into an array in the original order
  const result: DefaultReactSuggestionItem[] = [];

  // Determine the order of groups from the original items
  const groupOrder: string[] = [];
  defaultItems.forEach((item) => {
    const group = item.group || 'Other';
    if (!groupOrder.includes(group)) {
      groupOrder.push(group);
    }
  });

  // Add items in the correct group order
  groupOrder.forEach((group) => {
    if (groupedItems[group]) {
      result.push(...groupedItems[group]);
    }
  });

  // Add any remaining groups not in the original order
  Object.keys(groupedItems).forEach((group) => {
    if (!groupOrder.includes(group)) {
      result.push(...groupedItems[group]);
    }
  });

  return result;
};

export function BlockNote({
  initialContent,
  maxHeightClass,
  onChange,
  showHeadings = true,
  uploadFile,
}: {
  initialContent: Block[] | undefined;
  maxHeightClass?: string;
  onChange: (changes: Block[]) => void;
  showHeadings?: boolean;
  uploadFile?: (file: File) => Promise<string>;
}) {
  const schema = createSchema(showHeadings);

  const editorOptions = {
    schema,
  } as const;

  if (initialContent?.length) {
    Object.assign(editorOptions, { initialContent });
  }

  if (uploadFile) {
    Object.assign(editorOptions, { uploadFile });
  }

  const editor = useCreateBlockNote(editorOptions);

  const handleChange = async () => {
    // Cast to any to bypass type checking for the custom block
    // @ts-expect-error - This includes a custom block
    onChange(editor.document);
  };

  return (
    <div className="space-y-1.5">
      <div
        className={` disabled:border-gray-200 ${maxHeightClass ?? ''} bg-white`}
      >
        <div className="h-full overflow-y-auto">
          <BlockNoteView
            data-custom-blocknote-css-two
            editor={editor}
            formattingToolbar={true}
            sideMenu={true}
            slashMenu={false}
            theme={'light'}
            onChange={handleChange}
          >
            {/* Replaces the default Slash Menu with our custom one that includes YouTube */}
            <SuggestionMenuController
              getItems={async (query) => {
                // Get all items including YouTube
                // @ts-expect-error - This is a custom block
                const allItems = getCustomSlashMenuItems(editor);

                // Filter items based on the query
                return filterSuggestionItems(allItems, query);
              }}
              triggerCharacter={'/'}
            />
          </BlockNoteView>
        </div>
      </div>
    </div>
  );
}

export const createUploadFileFunction = (companyId: string) => {
  return async function uploadFile(file: File) {
    const params = {
      // This transformation limits the image dimensions to a maximum width and height of 1000 pixels, maintaining the aspect ratio.
      // It also optimises the quality.
      eager: 'c_limit,w_1000,h_1000,q_auto:good',

      folder: `${process.env.NEXT_PUBLIC_CLOUDINARY_ROOT_FOLDER}/${companyId}/rich-text-editor-assets`,
    };
    const response = await fetch('/api/cloudinary-sign-url', {
      body: JSON.stringify({
        paramsToSign: params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const { signature, timestamp } = await response.json();

    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'api_key',
      process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY as string
    );
    formData.append('timestamp', timestamp);
    formData.append('signature', signature);
    formData.append('eager', params.eager);
    formData.append('folder', params.folder);

    const uploadResponse = await fetch(
      `https://api.cloudinary.com/v1_1/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/auto/upload`,
      {
        body: formData,
        method: 'POST',
      }
    );

    const uploadData = await uploadResponse.json();
    return Promise.resolve(uploadData.secure_url);
  };
};
