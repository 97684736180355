import { ShareholderBadgeIcon, Tooltip } from '../../../..';

interface Props {
  isUser?: boolean;
}

export const ShareholderBadge: React.ComponentType<Props> = ({ isUser }) => {
  return (
    <div className="flex items-center gap-2">
      <Tooltip
        hover
        containerClassname="flex items-center"
        content={
          isUser
            ? `You are a verified shareholder`
            : `This investor is a verified shareholder`
        }
      >
        <ShareholderBadgeIcon />
      </Tooltip>
    </div>
  );
};
