import React from 'react';
import { HandThumbUpIcon as HandThumbUpIconOutline } from '@heroicons/react-v2/24/outline';
import { HandThumbUpIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';

interface Props {
  light?: boolean;
  liked: boolean;
  loadingLike: boolean;
}

const LikeAnimation: React.ComponentType<Props> = ({
  light,
  liked,
  loadingLike,
}) => {
  if (liked || (loadingLike && !liked)) {
    return (
      <HandThumbUpIcon
        className={clsx(
          'h-5 w-5 scale-100 opacity-100 transition-all duration-150',
          light ? 'fill-white' : 'fill-company-primary',
          loadingLike && !liked
            ? 'scale-90 opacity-70'
            : 'scale-100 opacity-100'
        )}
      />
    );
  } else {
    return <HandThumbUpIconOutline className="h-5 w-5" />;
  }
};

export default LikeAnimation;
