import React from 'react';
import { Block } from '@blocknote/core';
import clsx from 'clsx';
import { LinkedinIcon, TwitterIcon } from '../../icons';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta } from '../types';
import { DynamicRenderBlockNote } from '../ui';
import CloudinaryImage from '../ui/cloudinary-image';
import { PlainText } from '../ui/plain-text';
import { emitBlockClickEvent } from '../utils';

export interface TeamBlockProps {
  blockId?: string;
  content: {
    description?: Block[] | string;
    heading?: string;
    members?: {
      bio: string;
      image: ImageMeta | null;
      imageAltText: string;
      linkedInUrl: string;
      name: string;
      role: string;
      twitterUrl: string;
    }[];
    variant?: 'one_column' | 'two_columns';
  };
}

const PLACEHOLDER_HEADING = 'Meet our leadership';

export const TeamBlock: React.ComponentType<TeamBlockProps> = ({
  blockId,
  content,
}) => {
  function renderDescription() {
    if (content.description) {
      if (typeof content.description === 'string') {
        return (
          <div className="prose mt-3 list-inside list-disc font-body text-lg leading-normal text-hubs-primary marker:text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary">
            {content.description}
          </div>
        );
      }

      return (
        <DynamicRenderBlockNote
          // tailwindcss/typography's plugin prose includes a max-w- on it, so use max-w-none to override, don't remove prose.
          // https://github.com/tailwindlabs/tailwindcss-typography
          className="blocknote prose max-w-none list-inside list-disc font-body text-lg leading-normal text-hubs-secondary marker:text-hubs-secondary prose-headings:text-hubs-secondary prose-p:text-hubs-secondary prose-a:text-hubs-secondary prose-strong:text-hubs-secondary prose-table:text-hubs-secondary"
          content={content.description as Block[]}
        />
      );
    }
    return null;
  }
  return (
    <div
      className="bg-hubs-background"
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div
        className={clsx(
          BLOCK_WIDTH_CLASS,
          `mx-auto grid max-w-7xl`,
          content.description && content.description.length > 1
            ? 'gap-10'
            : 'gap-4',
          content.variant === 'two_columns'
            ? 'grid-cols-1'
            : 'grid-cols-1 lg:grid-cols-5'
        )}
      >
        <div
          className={clsx(
            'lg:col-span-2',
            content.variant === 'two_columns' ? 'max-w-none' : 'max-w-2xl'
          )}
        >
          <BlockHeading>{content.heading ?? PLACEHOLDER_HEADING}</BlockHeading>
          {renderDescription()}
        </div>
        <ul
          className={clsx(
            'grid gap-10 lg:col-span-3',
            content.variant === 'two_columns'
              ? 'grid-cols-1 md:grid-cols-2 xl:gap-16'
              : 'grid-cols-1 divide-y xl:gap-12'
          )}
        >
          {content.members?.map((member, index) => (
            <li
              key={index}
              className={clsx(
                `grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8`,
                content.variant === 'one_column' && 'pt-10 first:pt-0 xl:pt-12'
              )}
            >
              {member.image && (
                <CloudinaryImage
                  alt={
                    member.imageAltText ||
                    member.image.originalFilename ||
                    'Team member image'
                  }
                  className="aspect-[4/5] h-auto w-full max-w-[240px] flex-none rounded-lg object-cover"
                  crop="fill"
                  gravity="face"
                  height={260}
                  src={member.image.cloudinaryUrl || ''}
                  width={208}
                />
              )}
              <div className="col-span-2 max-w-xl flex-auto">
                <div className="font-body text-xl font-semibold leading-normal tracking-normal text-hubs-primary">
                  {member.name}
                </div>
                <div className="font-body text-base leading-7 text-company-primary">
                  {member.role}
                </div>
                <div className="mt-4 font-body leading-normal text-hubs-secondary">
                  <PlainText allowShowMore content={member.bio} maxLines={5} />
                </div>
                <ul className="mt-6 flex gap-x-6">
                  {member.linkedInUrl && (
                    <li>
                      <a
                        className="text-gray-400 transition-colors hover:text-gray-500"
                        href={member.linkedInUrl}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <LinkedinIcon className="h-5 w-5" />
                      </a>
                    </li>
                  )}
                  {member.twitterUrl && (
                    <li>
                      <a
                        className="text-gray-400 transition-colors hover:text-gray-500"
                        href={member.twitterUrl}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="sr-only">Twitter</span>
                        <TwitterIcon className="h-5 w-5" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
