import React, { useCallback, useRef, useState } from 'react';
import analytics from '@analytics';
import { SelectorIcon, XIcon } from '@heroicons/react/outline';
import FilterIcon from '@heroicons/react/solid/FilterIcon';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'usehooks-ts';
import { Button } from '../button';
import { DateRangePicker } from '../date-range-picker';
import { HermesGenericModal } from '../hermes-generic-modal';
import Typography from '../typography';

export type FilterType = { key: string; value: string | boolean | Date };
type FilterWithLabel = FilterType & { label: LabelGetter | string };
type LabelGetter = (key: string, value: Date | string) => string;
type FilterFunctionType = (filters: FilterType[]) => void;

export const FilterDatePicker: React.FC<{
  selectedFilters: FilterType[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
}> = ({ selectedFilters, setSelectedFilters }) => {
  const [rangePickerOpen, setRangePickerOpen] = useState(false);
  const startDate = selectedFilters.find((sf) => sf.key === 'startDate')?.value;
  const endDate = selectedFilters.find((sf) => sf.key === 'endDate')?.value;

  const selectedText = () => {
    const startDisplay = startDate
      ? dayjs(startDate as Date).format('D/M/YYYY')
      : '';
    const endDisplay = endDate ? dayjs(endDate as Date).format('D/M/YYYY') : '';
    if (startDate || endDate) {
      return `${startDisplay} - ${endDisplay}`;
    }
    return 'Select date';
  };

  const ref = useRef(null);

  const handleClickOutside = () => {
    setRangePickerOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      <div className="relative mt-1">
        <div
          className={clsx(
            'relative w-full cursor-default rounded-md border border-secondary-grey-dark bg-hubs-fixed-white py-2 pl-3 pr-10 text-left text-base font-normal text-text-main shadow focus:border-secondary-grey-dark focus:outline-none'
          )}
          onClick={() => setRangePickerOpen((prev) => !prev)}
        >
          <span className="block truncate font-system">{selectedText()}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              aria-hidden="true"
              className={clsx('h-5 w-5 text-gray-400')}
            />
          </span>
        </div>
      </div>

      {rangePickerOpen && (
        <span
          ref={ref}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="date-range-picker-wrapper z-10"
        >
          <DateRangePicker
            endDate={(endDate as Date) ?? null}
            setEndDate={(v) =>
              setSelectedFilters((prev) => {
                if (v) {
                  return [
                    ...prev.filter((prev) => prev.key !== 'endDate'),
                    { key: 'endDate', value: v as Date },
                  ];
                } else {
                  return prev.filter((prev) => prev.key !== 'endDate');
                }
              })
            }
            setOpen={setRangePickerOpen}
            setStartDate={(v) =>
              setSelectedFilters((prev) => {
                if (v) {
                  return [
                    ...prev.filter((prev) => prev.key !== 'startDate'),
                    { key: 'startDate', value: v as Date },
                  ];
                }
                return prev.filter((prev) => prev.key !== 'startDate');
              })
            }
            startDate={(startDate as Date) ?? null}
          />
        </span>
      )}
    </>
  );
};

export const FilterCheckBox: React.FC<{
  keyValue: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFilters: FilterType[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
}> = ({ keyValue, label, onChange, selectedFilters, setSelectedFilters }) => {
  const isChecked = (key: string) =>
    selectedFilters.some((sf) => sf.key === key);

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    keyValue: string
  ) => {
    setSelectedFilters((prev) => {
      if (e.target.checked) {
        return [...prev, { key: keyValue, value: e.target.checked.toString() }];
      }
      return prev.filter((prev) => prev.key !== keyValue);
    });
  };

  return (
    <label
      className="flex cursor-pointer items-center gap-2 disabled:cursor-auto"
      htmlFor={keyValue}
    >
      <input
        checked={isChecked(keyValue)}
        className="checkbox text-company-primary checked:bg-company-primary"
        id={keyValue}
        type="checkbox"
        onChange={onChange ? onChange : (e) => handleCheck(e, keyValue)}
      />
      <Typography variant="button">{label}</Typography>
    </label>
  );
};

export const FilterModal: React.FC<{
  allowOverflow?: boolean;
  appliedFilters: FilterType[];
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  originalFilters: FilterType[];
  selectedFilters: FilterType[];
  setAppliedFilters:
    | React.Dispatch<React.SetStateAction<FilterType[]>>
    | FilterFunctionType;
  setSelectedFilters:
    | React.Dispatch<React.SetStateAction<FilterType[]>>
    | FilterFunctionType;
  title: string;
  type: 'announcements' | 'updates';
}> = ({
  allowOverflow,
  appliedFilters,
  children,
  onClose,
  open,
  originalFilters,
  selectedFilters,
  setAppliedFilters,
  setSelectedFilters,
  title,
  type,
}) => {
  const clearFilters = () => {
    setSelectedFilters([]);
    setAppliedFilters(originalFilters);
  };
  const onCancelFilterModal = () => {
    setSelectedFilters(appliedFilters);
    onClose();
  };
  const onSubmit = useCallback(() => {
    setAppliedFilters([...selectedFilters, ...originalFilters]);
    selectedFilters.forEach((filter) =>
      analytics.track('hermes_sort_or_filter', {
        feature: type,
        filter: filter.key,
        hubs_version: '1',
      })
    );
    onClose();
  }, [selectedFilters, onClose, setAppliedFilters, type, originalFilters]);

  return (
    <HermesGenericModal
      bottomModal
      allowOverflow={allowOverflow}
      headerButtonAction={clearFilters}
      headerButtonText="Clear all filters"
      headerButtonVariant="primary"
      headerText={title}
      headerTextAlign="center"
      open={open}
      primaryButtonAction={onSubmit}
      primaryButtonText="Apply filter"
      secondaryButtonAction={onCancelFilterModal}
      secondaryButtonText="Cancel"
      onClose={onClose}
    >
      {children}
    </HermesGenericModal>
  );
};

export const FilterButton: React.FC<{
  filtersLength: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ filtersLength, setModalOpen }) => {
  return (
    <Button
      fullWidth
      color="company"
      size="sm"
      onClick={() => setModalOpen((prev) => !prev)}
    >
      <FilterIcon className="size-5 fill-company-primary-text" />
      Filter
      {filtersLength && filtersLength > 0 ? ` (${filtersLength})` : null}
    </Button>
  );
};

export const AppliedFilterBadges: React.FC<{
  appliedFiltersWithLabels: FilterWithLabel[];
  originalFilters: FilterType[];
  resultsCount: number;
  setAppliedFilters:
    | React.Dispatch<React.SetStateAction<FilterType[]>>
    | FilterFunctionType;
  setSelectedFilters:
    | React.Dispatch<React.SetStateAction<FilterType[]>>
    | FilterFunctionType;
}> = ({
  appliedFiltersWithLabels,
  originalFilters,
  resultsCount,
  setAppliedFilters,
  setSelectedFilters,
}) => {
  return appliedFiltersWithLabels.length ? (
    <div className="mb-5 mt-8 flex flex-wrap items-center gap-3 md:mt-0">
      <Typography>
        {resultsCount} result{resultsCount === 1 ? '' : 's'}
      </Typography>

      {appliedFiltersWithLabels.map((filter) => (
        <button
          key={
            ['startDate', 'endDate'].includes(filter.key)
              ? dateGetter(filter.key, filter.value as Date)
              : filter.key
          }
          className="flex items-center gap-1 rounded-lg bg-hubs-fixed-white-accent px-2 py-1 text-hubs-fixed-dark transition-colors"
          onClick={() => {
            const newFilters = appliedFiltersWithLabels
              .filter((f) => f.key !== filter.key)
              .map((f) => ({ key: f.key, value: f.value }));
            setSelectedFilters(newFilters);
            setAppliedFilters([...newFilters, ...originalFilters]);
          }}
        >
          <Typography className="" variant="badge">
            {['startDate', 'endDate'].includes(filter.key)
              ? dateGetter(filter.key, filter.value as Date)
              : 'news_publishers' === filter.key
              ? newsPublisherGetter(filter.key, filter.value as string)
              : (filter.label as string)}
          </Typography>
          <XIcon className="h-4 w-4 text-gray-500" />
        </button>
      ))}
    </div>
  ) : null;
};

export function getAppliedFiltersWithLabels(
  appliedFilters: FilterType[],
  labels: Record<string, string | LabelGetter>
) {
  return appliedFilters
    .filter((filter) => filter.key !== 'tags')
    .map((filter) => {
      return { ...filter, label: labels[filter.key] };
    });
}

export const dateGetter = (key: string, value: Date | string) => {
  if (key === 'startDate') {
    return `From ${dayjs(value).format('D/M/YYYY')}`;
  }
  if (key === 'endDate') {
    return `To ${dayjs(value).format('D/M/YYYY')}`;
  }
  return '';
};

export const newsPublisherGetter = (key: string, value: string | Date) => {
  if (key === 'news_publishers') return `${value}`;
  return '';
};
