import React from 'react';
import { CheckIcon } from '@heroicons/react-v2/20/solid';
import { BellIcon } from '@heroicons/react-v2/24/outline';
import clsx from 'clsx';
import { Tooltip, ButtonProps, Button } from '../../../index';
import UnfollowModal from './modal/unfollow-modal';

interface Props {
  buttonLoading?: boolean;
  buttonVariant?: ButtonProps['variant'];
  className?: string;
  color?: ButtonProps['color'];
  companyName: string;
  displayText?: boolean;
  followedId: string;
  isFollow: boolean;
  isFullWidth?: boolean;
  isOpenUnfollowModal: boolean;
  onFollow: () => void;
  setIsOpenUnfollowModal: React.Dispatch<React.SetStateAction<boolean>>;
  size?: ButtonProps['size'];
  variant: 'announcement' | 'update' | 'question' | 'user';
}

const FollowButton: React.ComponentType<Props> = ({
  buttonLoading = false,
  buttonVariant,
  className,
  color,
  companyName,
  displayText = true,
  followedId,
  isFollow,
  isFullWidth,
  isOpenUnfollowModal,
  onFollow,
  setIsOpenUnfollowModal,
  size,
  variant,
}) => {
  const renderTooltipText = () => {
    switch (variant) {
      case 'question':
        return `${companyName}
      responds to this question`;

      case 'user':
        return `this investor asks a question about an announcement or update`;

      default:
        return `there are new questions on this ${variant}`;
    }
  };

  const renderFollowText = () => {
    if (isFollow) {
      return 'Following';
    }

    switch (variant) {
      case 'question':
        return 'Follow question';
      default:
        return 'Follow';
    }
  };

  const renderButton = () => {
    return (
      <Button
        className={clsx(
          className,
          'flex items-center',
          size === 'sm' ? '!rounded-full' : ''
        )}
        color={color}
        disabled={buttonLoading}
        size={size}
        variant={buttonVariant}
        onClick={() => (isFollow ? setIsOpenUnfollowModal(true) : onFollow())}
      >
        {isFollow ? (
          <CheckIcon className="h-5 w-5" />
        ) : (
          <BellIcon className="h-5 w-5" />
        )}
        {displayText && renderFollowText()}
      </Button>
    );
  };

  return (
    <>
      <Tooltip
        hover
        buttonClassName={clsx(isFullWidth && 'w-full')}
        className="max-w-[220px]"
        containerClassname={clsx(isFullWidth && 'w-full')}
        content={`Be notified when ${renderTooltipText()}`}
        place="bottom-end"
      >
        {renderButton()}
      </Tooltip>
      <UnfollowModal
        loading={buttonLoading}
        open={isOpenUnfollowModal}
        unfollowButtonAction={onFollow}
        variant={variant}
        onClose={() => setIsOpenUnfollowModal(false)}
      />
    </>
  );
};

export default FollowButton;
